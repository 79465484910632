@import "@/style/common.scss";

.commonWrapper {
  width: 100%;
  height: 100%;
  background: url("../../../assets/images/common/bg.png") no-repeat center;
  // background-size: contain;
  main {
    width: $maxWidth;
    height: 100%;
    flex: 1;
    margin: auto;
    padding-top: 40px;
    @include column_sb;
    .logo {
      width: 100%;
      height: 46px;
      display: block;
      img {
        height: 100%;
      }
    }
    .info {
      @include row_center;
      flex: 1;
      > div {
        flex: 1;
      }
      .description {
        p {
          height: 33px;
          font-size: 24px;
          color: $white_color;
          line-height: 33px;
          letter-spacing: 1px;
          margin-top: 10px;
          padding-left: 15px;
          b {
            display: inline-block;
            margin: 0 10px;
          }
        }
      }
      .switchBox {
        @include row_center;
      }
      .common {
        width: 540px;
        background: $white_color;
        margin: auto;
        margin-left: 60px;
        border-radius: 5px;
      }
    }
    .footer {
      width: 100%;
      height: 57px;
      line-height: 57px;
      font-size: 12px;
      @include row_end;
      color: #c0c2cb;
      span {
        color: $Active_Color;
      }
    }
  }
}

.formWrapper {
  padding: 30px;
  > h6 {
    height: 40px;
    line-height: 40px;
  }
  .switchBtn {
    display: flex;
    flex: 1;
    height: 45px;
    line-height: 45px;
    font-size: 18px;
    text-align: center;
    div {
      flex: 1;
      border-bottom: 1px solid #eaecef;
      @include common_btn;
      &.disable {
        cursor: not-allowed;
      }
      &.active {
        color: $Active_Color;
        border-color: $Active_Color;
      }
    }
  }
  .form {
    padding-top: 25px;
  }
  .linkBox {
    height: 20px;
    line-height: 20px;
    @include row_sb;
    a,
    .checkContent {
      font-size: 12px;
      color: $Gary_Color;
      display: flex;
      &.forgot {
        color: $Active_Color;
      }
      a {
        color: $Active_Color;
      }
      img {
        align-self: center;
        width: 24px;
        height: 16px;
        margin-right: 6px;
      }
      .checkBox {
        width: 18px;
        height: 18px;
        border: 1px solid $Active_Color;
        border-radius: 3px;
        margin-right: 5px;
        cursor: pointer;
        &.noCheck {
          border-color: $Gary_Color;
        }
        svg {
          width: 16px;
          height: 16px;
          fill: $Active_Color;
        }
      }
    }
  }
  .confirmBtn {
    @include confirm_btn;
    margin-top: 20px;
  }
  .confirmBtnErr {
    @include confirm_btn;
    margin-top: 20px;
    background: #f5f5f5;
    color: #858585;
  }
  .switchType {
    @include row_end;
    margin-top: 10px;
    p {
      color: #333;
      font-size: 12px;
      line-height: 20px;
      a {
        color: $Active_Color;
      }
    }
  }
  .sendCode {
    //@include row_sb;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .simpleInputV2 {
      margin: 0;
      width: 100% !important;
    }
    .getCode {
      width: 130px;
      height: rem(105px);
      font-size: 14px;
      background: #f5f5f5;
      line-height: rem(54px);
      text-align: center;
      padding-top: 10px;
      margin: 20px 0 0 20px;
      color: #ba6a16;
      @include common_btn;
    }
  }
}
