@import "../../../style/variable";

.common-copy{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: rem(40px);
  cursor: pointer;
  .C-Svg{
    width: rem(40px);
    height: rem(40px);
    svg{
      width: 20px;
      height: 20px;
      fill:$Active_Color;
    }
  }
}

.img-copy{
  display: inline-block;
  margin-left: 10px;
  height: 100%;
  img{
    width: 13px;
    height: 15px;
    vertical-align: middle;
    cursor: pointer;
  }
}