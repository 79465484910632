@import '@/style/common.scss';

.footer{
    width: 100%;
    height: rem(58);
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: rem(13);
    border-top: 1px solid $main_color;
    background: $white_color;
    @include dis-sb();
    a{
        width: 25%;
        text-align: center;
        @include dis-clm();
        justify-content: center;
        color: rgba(121,103,57,.81);;
        svg{
            width: rem(30);
            height: rem(30);
            margin:0 auto;
        }
    }
    .trade_a{
        position: relative;
        .cover{
            margin-top: rem(-20);
            svg{
                width: rem(45);  
                height: rem(45);  
                fill:rgba(121,103,57,.81);
            }
        }
    }
    &::before{
        content: "";
        position: absolute;
        left: 50%;
        width: rem(79);
        height: rem(20);
        margin-left: rem(-40);
        top:rem(-19.5);
        background: url("../../../assets/images/download.png")no-repeat center;
        background-size: contain;
    }
}