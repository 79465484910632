@import "../../../style/variable";

.login_history{
  height: 100%;
  max-width: 990px;
  @include column;
  h5{
    font-size: rem(32px);
    height: 93px;
    line-height: 45px;
    color:$Black_Font_Color;
    background: $White;
    padding: 24px;
    @include boxShadow;
  }
  .history_main{
    flex: 1;
    padding: 24px;
    background: $White;
    margin-top: 10px;
    @include boxShadow;
    .title{
      height: 33px;
      border-bottom: 1px solid #f5f5f5;
      color: $Black_Font_Color;
    }
    .history_list{
      flex: 1;
      .list_title ,.list{
        background: #f9f9fa;
        color: $Gary_Color;
        height: 44px;
        line-height: 44px;
        display: flex;
        font-size: 14px;
        padding: 0 15px;
        .country , .ip{
          width: 15%;
        }
        .system{
          width: 50%;
          p{
            max-width: 90%;
            overflow:hidden; //超出的文本隐藏
            text-overflow:ellipsis; //溢出用省略号显示
            white-space:nowrap; //溢出不换行
          }
        }
        .time{
          width: 20%;
        }
      }
      .list{
        background: $White;
        border-bottom: 1px solid #f5f5f5;
      }
    }
  }
}