@import "../../../style/variable";

#Common_template {
  width: 100%;
  height: 100%;
  background: url("../../../assets/images/common/bg.png") no-repeat center;
  main {
    width: $Max_Width;
    height: 94%;
    flex: 1;
    margin: auto;
    padding-top: 40px;
    @include column;
    .logo {
      width: 100%;
      height: 46px;
      display: block;
      img {
        height: 100%;
      }
    }
    .info {
      display: flex;
      flex: 1;
      >div {
        flex: 1;
      }
      .description {
        align-self: center;
        p {
          height: 33px;
          font-size: 24px;
          color: $White;
          line-height: 33px;
          letter-spacing: 1px;
          margin-top: 10px;
          padding-left: 15px;
          b {
            display: inline-block;
            margin: 0 10px;
          }
        }
      }
      .switch_box{
        display: flex;
      }
      .common {
        align-self: center;
        width: 540px;
        background: $White;
        margin: auto;
        margin-left: 60px;
        border-radius: 5px;
      }

    }
    .foot {
      width: 100%;
      height: 57px;
      line-height: 57px;
      font-size: 12px;
      display: flex;
      justify-content: flex-end;
      color: #c0c2cb;
      span {
        color: $Active_Color;
      }
    }
  }
}