@import "../../../style/variable";
@import "@/style/common.scss";

.position {
  margin-top: 10px;
  flex: 1;
  width: 100%;
  overflow: auto;
  @include column;
  justify-content: flex-start;
  .position_head {
    display: flex;
    justify-content: space-between;
    height: 46px;
    background: #151515;
    .nav{
      display: flex;
      justify-content: flex-start;
      line-height: 46px;
      color: $Gary_Color;
      @include common_btn;
      div{
        width: 150px;
        text-align: center;
        font-size: 14px;
        &.active{
          @include active_btn($Deep_Section_Bg, $Active_Color, $White);
        }
      }

    }
    .sellAll{
      padding-right: 15px;
      display: flex;
      .profit{
        align-self: center;
        label{
          color: $White;
          font-size: 14px;
        }
        b{
          font-size: 24px;
          display: inline-block;
          padding: 0 7px 0 15px;
        }
        i{
          color: $Gary_Color;
          font-size: 12px;
        }
      }
      .sell{
        @include common_btn;
        align-self: center;
        width: 120px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        text-align: center;
        color: $White;
        margin-left: 30px;
        background: linear-gradient(90deg,#ff0002, #f78c1c);
        border-radius: 2px;
      }
    }
  }
  .position_list{
    height: 498px;
    word-break: keep-all;
    @include column;
    .list_box{
      flex: 1;
      overflow: auto;
      .empty div{
        width: 150px;
        height: 100px;
        img{
          width: 100px;
        }
        b{
          margin-top: 10px;
        }
      }
    }
    .title, .list{
      display: flex;
      justify-content: space-between;
      height: 46px;
      line-height: 46px;
      user-select: none;
      background: $Deep_Section_Bg;
      font-size: 12px;
      color: $Gary_Color;
      padding-left: 20px;
      width: 100%;
      border-bottom: 1px solid $Black_Font_Color;
      position: relative;
      i{
        position: absolute;
        border: 6px solid transparent;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
        &.redB{
          border-top-color: $RAISE;
          border-left-color: $RAISE;
        }
        &.greenB{
          border-top-color: $FALL;
          border-left-color: $FALL;
        }
      }
      >div{
        width: 5.5%;
        text-align: right;
        white-space: nowrap;
        word-break: keep-all;
        &:nth-child(2){
          justify-content: flex-start;
        }
        &:last-child{
          position: sticky;
          right: 0;
        }
      }
      .position_short{
        width: 10%;
      }
      .position_long, .pending_long, .settle_long{
        width: 10%;
        display: flex;
        justify-content: flex-end;
      }
      .position_longer{
        width: 15%;
        display: flex;
        justify-content: center;
        div{
          height: 30px;
          line-height: 28px;
          text-align: center;
          align-self: center;
          border-radius: 2px;
          padding: 0 10px;
          color: $Active_Color;
          margin-right: 8px;
          font-size: 12px;
          //margin: 0 10px;
          @include common_btn;
          &:first-child{
            border: 1px solid $Active_Color;
            color: $Active_Color;
            &:hover{
              background: $Active_Color;
              color: $White;
            }
          }
          &:last-child{
            margin:0 0 0 8px ;
            border:1px solid $Gary_Color;
            color: $Gary_Color;
            &:hover{
              color: $Active_Color;
              border:1px solid $Active_Color;
            }
          }
        }
      }
      .id{
        justify-content: flex-end;
        p{
          width: 80%;
          text-overflow:ellipsis;
          overflow: hidden;
          word-break:break-all;
        }
        .common-copy{
          width: 14px;
          margin-left: 5px;
        }
      }
      .pending_longer{
        height: 100%;
        width: 10%;
        text-align: center;
        padding: 0 10px;
        @include column;
        justify-content: center;
        div{
          height: 30px;
          line-height: 28px;
          border-radius: 2px;
          border: 1px solid $Gary_Color;
          color: $Gary_Color;
          padding: 0 10px;
          &:hover{
            color: $Active_Color;
            border-color: $Active_Color;
          }
        }
      }
      .settle_short{
        width: 10%;
      }
      .settle_long{
        &.id{
         justify-content: center;
        }
      }
    }
    .list{
      height: 58px;
      line-height: 58px;
      background: $Black_Font_Color;
      color: $White;
      cursor: pointer;
      &:hover{
        background: $Deep_Section_Bg;
      }
    }
    &.settlement_list{
     .list{
       padding-right: 10px;
     }
    }
  }
}

.theme_dark{
  width: 400px!important;
  .ant-modal-close-x{
    svg{
      fill:$White;
    }
  }
  .ant-modal-header{
    background: #1b1d26;
    border-bottom: none;
    .ant-modal-title{
      color: $White;
    }
  }
  .ant-modal-body{
    background:#121319 ;
    .confirm_info{
      color: $Active_Color;
      text-align: center;
      padding: rem(40px) 0;
    }
  }
  .ant-modal-footer{
    padding: 0;
  }
  .title{
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom:1px solid rgba(255,255,255,0.05);
    color: $White;
    div{
      align-self: center;
      &:last-child{
        font-size: 24px;
        span{
          font-size: 14px;
        }
      }
    }
  }
  .slider_box{
    @include column;
    padding: 24px 0;
    border-bottom:1px solid rgba(255,255,255,0.05);
    .slider_title{
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      color: $White;
    }
    .controller{
      display: flex;
      justify-content: space-between;
      .btn{
        @include common_btn;
      }
      div{
        align-self: center;
      }
      .stopProfit, .stopLoss{
        width: 80%;
        .ant-slider-track{
          background-color:$FALL;
        }
        .ant-slider-handle{
          border-color:$FALL;
          &:focus{
            box-shadow: none;
          }
        }
      }
      .stopProfit{
        .ant-slider-track{
          background-color:$RAISE;
        }
        .ant-slider-handle{
          border-color:$RAISE;
        }
      }
    }
  }
  .btn_box{
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    div{
      width: 150px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border-radius: 3px;
      background: #282B33;
      color: $Gary_Color;
      @include common_btn;
      &.confirm{
        color: $White;
        background: $Active_Color;
      }
    }

  }
  .ant-modal-body{
    padding-bottom: 0;
  }
  .ant-modal-footer{
    border: none;
  }
}

.lever_spsl{
  width: 480px !important;
  .ant-modal-body{
    padding: 0;
    .oneLine{
      display: flex;
      height: 50px;
      justify-content: space-between;
      border-bottom:1px solid rgba(255,255,255,0.05);
      padding:0 15px;
      color: $White;
      line-height: 50px;
      div{
        display: flex;
        img{
          align-self: center;
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
      }
      b{
        font-size: 18px;
        span{
          color: $White;
          font-size: 14px;
        }
      }
      &.switch_btn{
        justify-content: center;
        div{
          width: 120px;
          justify-content: center;
          @include common_btn;
        }
        .active{
          color:$Active_Color;
          border-bottom: 1px solid $Active_Color;
        }
      }
    }
    .twoLine{
      padding: 10px 15px;
      border-bottom:1px solid rgba(255,255,255,0.05);
      @include column;
      label{
        color:$White;
      }
      .emulate-input{
        margin: 8px 0;
        input{
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          color: $Active_Color;
        }
        .controller{
          right: 0;
        }
      }
      >div{
        display: flex;
        justify-content: space-between;
        span{
          color: $White;
        }
        b{
          color: $Active_Color;
        }
      }

    }
    .btn_box{
      margin: 0 15px;
      div{
        width: 200px;
      }
    }
  }
}

.spslModal {
	width: 480px;
	background: #121319;
	font-size: 14px;
	.oneLine {
		@include row_sb;
		height: 50px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.05);
		padding: 0 15px;
		color: $white_color;
		line-height: 50px;
		div {
			display: flex;
			img {
				align-self: center;
				width: 22px;
				height: 22px;
				margin-right: 5px;
			}
		}
		b {
			font-size: 18px;
			span {
				color: $white_color;
				font-size: 14px;
			}
		}
		&.switchBtn {
			justify-content: center;
			div {
				width: 120px;
				justify-content: center;
				@include common_btn;
			}
			.active {
				color: $Active_Color;
				border-bottom: 1px solid $Active_Color;
			}
		}
	}
	.twoLine {
		padding: 10px 15px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.05);
		@include column_sb;
		label {
			color: $white_color;
		}
		.inputBox {
			margin: 8px 0;
			position: relative;
			border: 1px solid #858585;
			border-radius: 2px;
			flex: 1;
			height: 30px;
			input {
				flex: 1;
				padding-left: 6px;
				height: 34px;
				line-height: 34px;
				font-size: 14px;
				background: transparent;
				border: none;
				box-shadow: none;
				outline: none;
				width: 90%;
				color: $Active_Color;
			}
			.controller {
				@include row_sb;
				position: absolute;
				width: 46px;
				right: 6px;
				height: 34px;
			}
			&:hover {
				border-color: $Active_Color;
			}

			&.focus {
				border-color: $Active_Color;
			}
		}
		> div {
			display: flex;
			justify-content: space-between;
			span {
				color: $white_color;
			}
			b {
				color: $Active_Color;
			}
		}
	}
	.sliderBox {
		@include column_sb;
		padding: 24px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.05);
		.sliderTitle {
			@include row_sb;
			padding-bottom: 20px;
			color: $white_color;
		}
		.controller {
			@include row_sb;
			.btn {
				@include common_btn;
			}
		}
	}
	.btnBox {
		@include row_sb;
		padding: 20px 0;
		margin: 0 15px;
		div {
			width: 200px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			border-radius: 3px;
			background: #282b33;
			color: #858585;
			@include common_btn;
		}
		.confirm {
			color: #fff;
			background: #f78c1c;
		}
	}
}