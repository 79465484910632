@import "../../../style/variable";

.simple_input {
  @include column;
  position: relative;
  .input_title {
    display: flex;
    font-size: 12px;
    height: 16px;
    line-height: 16px;
    justify-content: space-between;
    label {
      color: $Gary_Color;
    }
    a {
      color: $Active_Color;
    }
  }
  .input_box {
    position: relative;
    margin-bottom: 24px;

    .email_address {
      position: absolute;
      width: 100%;
      z-index: 20;
      top: 60px;
      left: 0;
      @include boxShadow;
      li {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 24px;
        background: $White;
        @include common_btn;
        &:hover {
          background: #f5f5f5;
        }
      }
    }
    input {
      width: 100%;
      height: rem(54px);
      outline: none;
      background: #f5f5f5;
      line-height: rem(54px);
      font-size: rem(16px);
      padding: 0 24px;
      border: none;
      border-radius: 2px;
      margin-top: 5px;
      &::placeholder {
        font-size: 14px;
        color: $Gary_Color;
      }
    }
    .eye {
      position: absolute;
      width: 20px;
      height: 15px;
      bottom: rem(18px);
      right: rem(18px);
      z-index: 2;
      .C-Svg {
        width: 100%;
        height: 100%;
        svg {
          width: 25px;
          height: 20px;
        }
      }
    }
  }
  .error {
    position: absolute;
    white-space: nowrap;
    left: 0;
    bottom: 5px;
    font-size: 12px;
    line-height: 14px;
    color: $RAISE;
  }
}
