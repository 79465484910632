@import '@/style/common.scss';

.live800{
    height: 100%;
    @include dis-clm();
    .main{
        height: calc(100% - 60px);
        position: relative;
        overflow: auto;
        iframe {
          height: 100%;
        }
}
}