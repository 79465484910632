@import "../../style/variable";
.notice {
  width: 100%;
  background: #f1f1f1;
  .banner {
    img {
      display: block;
      width: 100%;
    }
  }
  .body {
    width: 60rem;
    margin: 0 auto;
    display: flex;
    display: -webkit-flex;
    .paging {
      background: transparent;
      .device {
        margin-bottom: 1rem;
      }
    }
    .title {
      position: relative;
      background-color: #f7f7f7;
      padding: 0.5rem 0;
      padding-left: 1rem;
      font-weight: 600;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      border-bottom: 1px solid #e6e6e6;
      font-size: rem(16px);
      color: #000;
      &::before {
        content: "";
        width: 0.15rem;
        background: #353f59;
        height: 0.75rem;
        border-radius: 0.15rem;
        left: 0.5rem;
        top: 0.45rem;
        position: absolute;
      }
    }
    .left {
      -webkit-box-flex: 0.22;
      -webkit-flex: 0.22;
      flex: 0.22;
      padding-right: 1.2rem;
      .rank {
        padding: 0.6rem 0.5rem;
        background-color: #fff;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        font-size: rem(15);
        ul {
          li {
            padding: 0.5rem 0;
            color: #a8a7a4;
            padding-bottom: 0.25rem;
            padding-right: 0.15rem;
            border-bottom: 1px solid #f1f1f1;
            cursor: pointer;
            &:hover {
              color: #d1a24c;
            }
            &:last-child {
              border: none;
            }
          }
        }
      }
    }
    .right {
      -webkit-box-flex: 0.78;
      -webkit-flex: 0.78;
      flex: 0.78;
      min-height: 25rem;
      .conn {
        background-color: #fff;
        padding-left: 0.75rem;
        padding-bottom: 0.5rem;
        ul {
          li {
            width: 100%;
            display: flex;
            display: -webkit-flex;
            padding: 0.75rem 0;
            border-bottom: 1px solid #e6e6e6;
            cursor: pointer;
            &:last-child {
              border: none;
            }
            .date {
              -webkit-box-flex: 0.12;
              -webkit-flex: 0.12;
              flex: 0.12;
              text-align: center;
              padding-top: 0.85rem;
              h5 {
                font-size: 1.2rem;
                line-height: 2rem;
                color: #999999;
              }
              p {
                font-size: rem(16px);
                color: #000;
              }
            }
            .content {
              -webkit-box-flex: 0.78;
              -webkit-flex: 0.78;
              flex: 0.78;
              display: flex;
              padding: 0.25rem 1.25rem 0 0.4rem;
              justify-content: center;
              flex-direction: column;
              font-size: 0.9rem;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.notice.noticeDetails {
  .body {
    padding-bottom: 1.5rem;
    .right {
      -webkit-box-flex: 0.78;
      -webkit-flex: 0.78;
      flex: 0.78;
      min-height: 30rem;
      background-color: #fff;
      //min-height: 35rem;
      .title0 {
        padding: 1.5rem 1rem 0 1rem;
        border-bottom: 1px dashed #e6e6e6;
        position: relative;
        h5 {
          font-size: 1.4rem;
        }
        p {
          padding: 1.25rem 0;
          color: #a8a7a4;
          font-size: 0.8rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .icon {
            margin: 0;
            margin-right: rem(8px);
          }
        }
        .fh {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .content {
        padding: 1rem;
        color: #333333;
        text-align: justify;
        font-size: 0.75rem;
        line-height: 1.45rem;
      }
    }
  }
}
