@import "../../../style/variable";

#Login{
  padding: 30px;
  >h6{
    height: 40px;
    line-height: 40px;
  }
  .switch_btn{
    display: flex;
    flex: 1;
    height: 45px;
    line-height: 45px;
    font-size: 18px;
    text-align: center;
    div{
      flex: 1;
      border-bottom: 1px solid #eaecef;
      @include common_btn;
      &.disable{
        cursor:not-allowed;
      }
      &.active{
        color: $Active_Color;
        border-color: $Active_Color;
      }
    }
  }
  .mobile ,.email, .resetLogin{
    padding-top: 25px;
  }
  .link_box{
    height: 20px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    a{
      font-size: 12px;
      color: $Gary_Color;
      display: flex;
      &.forgot{
        color: $Active_Color;
      }
      a{
        color: $Active_Color;
      }
      img{
        align-self: center;
        width: 24px;
        height: 16px;
        margin-right: 6px;
      }
      .check_box{
        width: 18px;
        height: 18px;
        border: 1px solid $Active_Color;
        border-radius: 3px;
        margin-right: 5px;
        &.no_check{
          border-color: $Gary_Color;
        }
        .C-Svg{
          width: 16px;
          height: 16px;
          svg{
            width: 100%;
            height: 100%;
            fill:$Active_Color;
          }
        }
      }
    }
  }
  .confirm_btn{
    margin-top: 20px;
  }
  .switch_type{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    p{
      color: #333;
      font-size: 12px;
      line-height: 20px;
      a{
        color: $Active_Color;
      }
    }
  }
  .sendCode{
    display: flex;
    justify-content: space-between;
    .simple_input{
      margin:0;
      width: 100%;
    }
    .getCode{
      width: 130px;
      height: rem(54px);
      font-size: 14px;
      background: #f5f5f5;
      line-height: rem(54px);
      text-align: center;
      margin: 20px 0 0 20px;
      color: #ba6a16;
      @include common_btn;
    }
  }
}