@import '@/style/common.scss';

.alertCover{
    width: 80%;
    margin: rem(200) 10% 0;
    background: $white_color;
    border-radius: rem(3);
    .header{
        font-size: rem(18);
        height: rem(50);
        line-height: rem(50);
        border-bottom: 1px solid $main_color;
        text-align: center;
    }
    .content{
        min-height: rem(100);
        @include dis-clm();
        justify-content: center;
        color: $gray_color;
        font-size: rem(14);
        >div{
            text-align: center;
            line-height: rem(24);
        }
    }
    .btn_box{
        border-top: 1px solid $main_color;
        display: flex;
        height: rem(44);
        line-height: rem(44);
        div{
            flex: 1;
            text-align: center;
            font-size: rem(14);
            &:first-child{
                color: $main_color;
            }
            &.cancel{
                border-left: 1px solid $main_color;
            }
        }
    }
}