@import "../../../style/variable";

.Recharge {
  .recharge_title {
    height: 93px;
    padding: 24px;
    display: flex;
    background: $White;
    
    @include boxShadow;
    h5 {
      align-self: center;
      font-size: rem(32px);
      line-height: 38px;
      font-weight: 600;
    }
  }
  .recharge_main {
    @include boxShadow;
    margin-top: 10px;
    .switch_btn {
      display: flex;
      justify-content: flex-start;
      height: 46px;
      line-height: 46px;
      background: $White;
      padding: 0 24px;
      font-weight: 600;
      //color:#121319;
      @include common_btn;
      border-bottom: 1px solid #f5f5f5;
      div {
        margin-right: 40px;
        &.active {
          border-bottom: 2px solid $Active_Color;
          color:#121319;
        }
      }
    }
    .crypto_recharge {
      display: flex;
      padding: 24px;
      .rechargeTips{
        margin-left: 350px; 
        color: $Active_Color;
          &:hover {
            cursor: pointer;
          }  
      }  
      .info_right {
        flex: 1;
        @include column;
        .select_coin {
          @include column;
          label {
            font-size: 12px;
            line-height: 16px;
          }
          .ant-select {
            width: 100%;
            align-self: center;
            margin-top: 5px;
            border-color: #333;
            &:hover{
              .ant-select-selection{
                border-color: $Active_Color;
                .anticon svg{
                  fill:$Active_Color;
                }
              }
            }
            .ant-select-selection {
              border: 1px solid rgb(211, 211, 211);
              height: rem(54px);
              line-height: rem(54px);
              &:focus {
                box-shadow: none;
              }
              .ant-select-selection__rendered {
                line-height: rem(54px);
                display: flex;
              }
              .ant-select-arrow{
                  top: 45%;
              }
              .anticon{
                width: 20px;
                height: 16px;
                svg{
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .ant-select-selection-selected-value {
              display: flex !important;
              height: 30px;
              align-self: center;
              img {
                align-self: center;
                margin: 5px;
                width: 20px;
                height: 20px;
              }
              b {
                align-self: center;
              }
              span {
                align-self: center;
              }
              .default {
                display: flex;
              }
            }
          }
        }
        .balance {
          font-size: 13px;
          margin-top: 20px;
          font-weight: 600;
          color:#121319;
          span {
            font-size: 12px;
          }
        }
        .tips {
          color: $Black_Font_Color;
          h6 {
            font-size: 14px;
            margin-bottom: 6px;
            font-weight: 600;
            color:#121319;
          }
          ul {
            li {
              line-height: 20px;
              font-size: 12px;
              color: #121319;
              font-weight: 600;
              margin-top: rem(6px);
             
            }
          }
          .tipsImportant{
            background-color: rgba(247,140,28,.17);
            color:"#F78C1C"
           // opacity: 0.17;
          }
        }
      }
      .info_left {
        flex: 1;
        @include column;
        padding-left: 30px;
        .link {
          @include column;
          label {
            font-size: 12px;
            color: $Gary_Color;
            line-height: 16px;
          }
          .link_name {
            margin-top: 5px;
            display: flex;
            > div {
              height: rem(54px);
              width: 126px;
              text-align: center;
              line-height: rem(54px);
              border-radius: 2px;
              background: #f5f5f5;
              margin-right: 20px;
              @include common_btn;
              &.active {
                background: $Active_Color;
                color: $White;
              }
            }
          }
        }
        .QR {
          width: 120px;
          height: 120px;
          margin: 30px auto 40px;

        }
        .address {
          background: #f5f5f5;
          font-size: 13px;
          padding: 0 20px;
          > div {
            display: flex;
            justify-content: space-between;
            height: rem(54px);
            line-height: rem(54px);
            .common-copy {
              align-self: center;
            }
          }
        }
      }
    }

    .fiat_recharge {
      padding: 10px 24px 24px;
      background: $White;
      @include column;
      label {
        line-height: 16px;
        color: $Gary_Color;
        font-size: 12px;
      }
      .fiat_balance {
        padding: 23px 0;
        p {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 4px;
        }
        div {
          b {
            font-size: 24px;
            color: $Active_Color;
            span {
              font-size: 12px;
              color: $Black_Font_Color;
            }
          }
          i {
            display: inline-block;
            margin-left: 20px;
            font-size: 12px;
            color: $Gary_Color;
          }
        }
      }
      .pay_method {
        label {
          display: block;
          margin-bottom: 5px;
        }
        .pay_box {
          display: flex;
          flex-wrap: wrap;
          .pay {
            width: 460px;
            display: flex;
            padding: 12px 0 12px 20px;
            border: 1px solid #F5F5F5;
            border-radius: 3px;
            margin: 0 10px 20px 0;
            @include common_btn;
            @include boxShadow;
            &.active {
              border-color: $Active_Color;
            }
            &:hover{
              border-color: $Active_Color;
            }
            img {
              width: 30px;
              height: 30px;
              align-self: center;
              margin-right: 10px;
            }
            div {
              display: flex;
              justify-content: center;
              flex-direction: column;
              b {
                display: block;
                margin-bottom: 5px;
              }
              p {
                font-size: 12px;
                padding-top: 3px;
                color: $Gary_Color;
              }
            }
          }
        }
      }
      .pay_money {
        display: flex;
        justify-content: flex-start;
        .simple_input {
          margin-right: 15px;
        }
        .money {
          width: 70px;
          background: #f5f5f5;
          font-size: 14px;
          height: rem(54px);
          line-height: rem(54px);
          align-self: flex-start;
          margin: 20px 15px 0;
          text-align: center;
          border-radius: 2px;
          @include common_btn;
          &.active {
            background: $Active_Color;
            color: $White;
          }
        }
      }
      .submit_box {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        > div {
          flex: 1;
          &.pay_btn {
            @include column
          }
          &.tips {
            display: flex;
            justify-content: flex-end;
            h6 {
              font-size: 14px;
              line-height: 20px;
            }
            p {
              font-size: 12px;
              line-height: 17px;
              a {
                color: $Active_Color;
              }
            }
          }
        }
      }
    }
  }

  .recent_record {
    @include boxShadow;
    height: 374px;
    padding: 24px;
    background: $White;
    margin-top: 10px;
    .record {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f5f5f5;
      h5 {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        border-bottom: 2px solid $Active_Color;
        font-weight: 600;
        color:#121319
      }
      a {
        font-size: 12px;
        color: $Active_Color;
        align-self: center;
      }
    }
    ul {
      flex: 1;
      position: relative;
      &.crypto_ul{
        div{
          flex: none;
          width: 12%;
        }
        .longer{
          width: 40%;
        }
      }
      li {
        display: flex;
        justify-content: space-between;
        height: 44px;
        line-height: 44px;
        border-bottom: 1px solid #f5f5f5;
        padding: 0 16px;
        &.record_title {
          background: #f5f5f5;
          margin-top: 15px;
          color: $Gary_Color;
          div{
            font-size: 12px;
          }
        }
        div {
          flex: 1;
          font-size: 14px;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
    .empty{
      height: 70%;
    }
  }
}