@import "../../style/variable";

#Markets {
  @include column;
  height: 100%;
  .main {
    flex: 1;
    background: $Black_Bg;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    .market_list {
      flex: 1;
      background: $Deep_Section_Bg;
      .market_nav {
        height: 44px;
        display: flex;
        border-bottom: 1px solid $Line_Color;
        background: #000;
        div {
          font-size: 14px;
          color: rgba(192, 194, 203, 1);
          line-height: 44px;
          padding: 0 20px;
          @include common_btn;
          &.active {
            color: $Active_Color;
            background: #252525;
          }
          &:hover {
            color: $Active_Color;
            background: #252525;
          }
        }
      }
      .list {
        padding: 0 5px 0 20px;
        max-height: 786px;
        .title_box {
          display: flex;
          padding-right: 15px;
          .title {
            flex: .9;
            display: flex;
            height: 57px;
            padding: 0 10px 0 50px;
            div {
              align-self: center;
              flex: 1;
              font-size: 12px;
              color: $Gary_Color;
              display: flex;
              justify-content: flex-end;
            }
            .code, .name {
              justify-content: flex-start;
            }
          }
          a {
            flex: .1;
          }
        }
        .list_box{
          height: 729px;
          overflow: auto;
          .item_box {
            display: flex;
            padding-right: 15px;
            border-bottom: 1px solid $Line_Color;
            &:hover {
              background: #252525;
            }
            .item {
              flex: .9;
              display: flex;
              height: 49px;
              padding: 0 10px 0 50px;
              position: relative;
              color: $White;
              @include common_btn;
              .img {
                position: absolute;
                left: 5px;
                img {
                  width: 30px;
                  height: 30px;
                }
              }
              > div {
                align-self: center;
                flex: 1;
                font-size: rem(14px);
                display: flex;
                justify-content: flex-end;
              }
              .code, .name {
                justify-content: flex-start;
                color: $White;
              }
            }
            a {
              flex: .1;
              display: flex;
              justify-content: flex-end;
              div {
                align-self: center;
                width: 60px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                border-radius: 3px;
                font-size: 12px;
                background: $Active_Color;
                color: $Deep_Section_Bg;
              }
              &.restBtn{
                div{
                  background: #333;
                  color: $Gary_Color;
                }
              }
            }
          }
        }
      }
    }
    .market_info {
      width: 430px;
      margin-left: 10px;
      @include column;
      .info {
        background: $Deep_Section_Bg;
        flex: 1;
        .goods {
          padding: 20px 20px 10px;
          .name {
            color: $White;
            font-size: 18px;
            display: flex;
            justify-content: space-between;
            .favor {
              align-self: center;
            }
          }
          .price_box {
            margin-top: 10px;
            display: flex;
            .price {
              font-size: 20px;
              align-self: center;
            }
            .rate {
              align-self: flex-end;
              font-size: 14px;
              margin: 0 20px;
            }
            span {
              font-size: 12px;
              height: 20px;
              line-height: 20px;
              padding: 0 6px;
              border: 1px solid $Gary_Color;
              border-radius: 2px;
              color: $Gary_Color;
            }
          }
        }
      }
    }
  }
}

.rule {
  padding: 0 10px;
  flex: 1;
  max-height: 750px;
  overflow-y: auto;
  h5 {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    text-align: center;
    color: $Active_Color;
    border-top: 1px solid $Line_Color;
    border-bottom: 1px solid $Line_Color;
  }
  div {
    display: flex;
    padding: 5px;
    line-height: 30px;
    justify-content: space-between;
    font-size: 12px;
    border-bottom: 1px solid $Line_Color;
    color: $White;
    label {
      color: $Gary_Color;
    }
    .search{
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: right;
      //display: flex;
      //justify-content: flex-start;
    }
    &.production {
      @include column;
      padding: 5px 0 15px;
      b {
        height: 37px;
        text-align: center;
        line-height: 37px;
        color: $Active_Color;
        font-size: 14px;
      }
      span {
        line-height: 24px;
        font-size: 14px;
        color: $Gary_Color;
        text-indent: 26px;
      }
    }
  }
}