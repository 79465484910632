@import "../../../style/variable";

#Header {
  background: $Black_Font_Color;
  height: 66px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  position: relative;
  z-index: 10;
  padding: 0 20px;
  overflow: hidden;
  
  .head_left {
    display: flex;
    a {
      padding: 0 10px;
      line-height: 66px;
      display: flex;
      justify-content: center;
      color: $White;
      @include common_btn;
      img {
        align-self: center;
      }
      &.active {
        color: $Active_Color;
      }
      &:hover {
        color: $Active_Color;
      }
    }
  }
  .head_right {
    display: flex;
    align-items: center;
    .btn_box {
      display: flex;
      a {
        width: 80px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        color: $White;
        background: linear-gradient(90deg, #ff0002, #f78c1c);
        border: 2px;
        margin: 0 10px;
        align-self: center;
        border-radius: 2px;
        &:last-child {
          background: $Black_Font_Color;
        }
      }
    }
    > a {
      display: flex;
      width: 56px;
      height: 52px;
      justify-content: center;
      position: relative;
      .C-Svg {
        align-self: center;
        width: 16px;
        height: 16px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .unread{
        position: absolute;
        top: 18px;
        right: 12px;
        width: 12px;
        height: 16px;
        line-height: 16px;
        font-size: 12px;
        text-align: center;
        border-radius: 5px;
        color: $White;
        background: $Active_Color;
      }
      &:hover {
        .C-Svg svg {
          fill: $Active_Color;
        }
      }
    }
  }
}

.ant-popover-placement-bottom {
  padding-top: 0 !important;
  .ant-popover-arrow {
    border-color: #252525 !important;
  }
  .ant-popover-inner {
    background: #252525 !important;
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.08) !important;
    .ant-popover-inner-content {
      padding: 0 !important;
      .nav {
        position: relative;
        padding: 10px 0;
        @include column;
        div {
          margin-bottom: 10px;
          border-bottom: 1px solid $Black_Font_Color;
          line-height: 0;
          height: 60px;
          padding: 10px 25px;
          b, p {
            line-height: 20px;
            font-size: 14px;
            display: block;
            color: $White;
            user-select: none;
          }
          p {
            font-size: 12px;
            color: $Gary_Color;
          }
        }
        a {
          height: 40px;
          width: 200px;
          font-size: 14px;
          padding: 0 25px;
          line-height: 40px;
          color: $White;
          &:hover {
            color: $Active_Color;
            background: #333;
          }
        }
      }
      .download{
        display: flex;
        padding: 15px;
        img{
          width: 80px;
          height: 80px;
        }
        div{
          margin-left: 15px;
          @include column;
          b{
            color: $Active_Color;
          }
          p{
            color: $White;
            font-size: 12px;
          }
          a{
            display: block;
            height: 26px;
            width: 80px;
            line-height: 26px;
            border-radius: 2px;
            font-size: 12px;
            text-align: center;
            color: $White;
            background: $Active_Color;
          }
        }
      }
    }
  }
}

.message_box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0,0,0,.4);
  width: 100%;
  height: 100%;
  .message_main{
    width: 520px;
    height: 600px;
    background: $White;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    border-radius: 4px;
    @include column;
    .message_head{
      width: 100%;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      box-shadow: 0 0 2px $Font_Color;
      b{
        font-size: 20px;
      }
      img{
        align-self: center;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
    .message_ul{
      padding:24px 24px 10px;
      height: 450px;
      flex: 1;
      overflow: auto;
      .message_li{
        margin-bottom: 30px;
        .info{
          display: flex;
          justify-content: flex-start;
          img{
            width: 40px;
            height: 40px;
          }
          p{
            background: rgba(178,178,178,.4);
            color: $Deep_Section_Bg;
            margin-left: 20px;
            padding:10px 15px;
            border-radius: 3px;
            line-height: 20px;
          }
        }
        .time{
          display: flex;
          justify-content: flex-end;
          font-size: 12px;
          color: $Gary_Color;
        }
      }
    }
  }

}
