@import "../../../../style/variable";

.ant-modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding: 0 0 24px;
  pointer-events: none;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.ant-modal-mask-hidden {
  display: none;
}

.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  overflow: auto;
  outline: 0;
}

.aboutPass {
  .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    pointer-events: auto;
  }
  .confirm_btn {
    margin-top: 15px;
  }
  .sendCode {
    display: flex;
    justify-content: space-between;
    .simple_input {
      margin: 0;
      width: 100%;
    }
    .getCode {
      align-self: flex-start;
      width: 130px;
      height: rem(54px);
      margin-top: 20px;
      background: #f5f5f5;
      line-height: rem(54px);
      text-align: center;
      margin-left: 20px;
      color: #ba6a16;
      font-size: 14px;
      @include common_btn;
    }
  }
  .switch_verify {
    display: flex;
    justify-content: flex-end;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: $Active_Color;
    > div {
      @include common_btn;
    }
  }
  .Zone {
    display: flex;
    justify-content: space-between;
    .simple_select {
      width: 45%;
    }
  }
  &.addBankcard {
    .bank_box {
      display: flex;
      justify-content: flex-start;
      height: 120px;
      padding: 20px;
      margin-bottom: 25px;
      .info {
        padding-left: 15px;
        color: $White;
        @include column;
        .bank_name {
          b {
            font-size: 19px;
          }
          p {
            font-size: 12px;
          }
        }
        .bank_card {
          height: 26px;
          font-size: 19px;
          text-align: center;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: 1px;
        }
      }
    }
  }
  .icbc {
    background: url("../../../../assets/images/userCenter/bank/bankBg/ICBC.png")
      no-repeat center;
    background-size: 120%;
  }
  .cmb {
    background: url("../../../../assets/images/userCenter/bank/bankBg/CMB.png")
      no-repeat center;
    background-size: 120%;
  }
  .ccb {
    background: url("../../../../assets/images/userCenter/bank/bankBg/CCB.png")
      no-repeat center;
    background-size: 120%;
  }
  .abc {
    background: url("../../../../assets/images/userCenter/bank/bankBg/ABC.png")
      no-repeat center;
    background-size: 120%;
  }
  .boc {
    background: url("../../../../assets/images/userCenter/bank/bankBg/BOC.png")
      no-repeat center;
    background-size: 120%;
  }
  .comm {
    background: url("../../../../assets/images/userCenter/bank/bankBg/COMM.png")
      no-repeat center;
    background-size: 120%;
  }
  .cmbc {
    background: url("../../../../assets/images/userCenter/bank/bankBg/CMBC.png")
      no-repeat center;
    background-size: 120%;
  }
  .spcb {
    background: url("../../../../assets/images/userCenter/bank/bankBg/SPDB.png")
      no-repeat center;
    background-size: 120%;
  }
  .citic {
    background: url("../../../../assets/images/userCenter/bank/bankBg/CITIC.png")
      no-repeat center;
    background-size: 120%;
  }
  .gbd {
    background: url("../../../../assets/images/userCenter/bank/bankBg/GDB.png")
      no-repeat center;
    background-size: 120%;
  }
  .szpab {
    background: url("../../../../assets/images/userCenter/bank/bankBg/SZPAB.png")
      no-repeat center;
    background-size: 120%;
  }
  .cib {
    background: url("../../../../assets/images/userCenter/bank/bankBg/CIB.png")
      no-repeat center;
    background-size: 120%;
  }
  .hxb {
    background: url("../../../../assets/images/userCenter/bank/bankBg/HXB.png")
      no-repeat center;
    background-size: 120%;
  }
  .ceb {
    background: url("../../../../assets/images/userCenter/bank/bankBg/CEB.png")
      no-repeat center;
    background-size: 120%;
  }
  .psbc {
    background: url("../../../../assets/images/userCenter/bank/bankBg/PSBC.png")
      no-repeat center;
    background-size: 120%;
  }
  .bcs {
    background: url("../../../../assets/images/userCenter/bank/bankBg/BCS.png")
      no-repeat center right;
    background-size: 100%;
  }
  .common {
    background: url("../../../../assets/images/userCenter/bank/bankBg/card-bg.png")
      no-repeat center;
    background-size: 120%;
  }
}
