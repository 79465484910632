@import "../../style/variable";

.school{
  width: 100%;
  background-color: #f1f1f1;
  //overflow-y: auto;
  height: auto;
  >.wrap{
    background-color: #f1f1f1;
    >.box{
      width: 60rem;
      margin: 0 auto;
      display: flex;
      display: -webkit-box;
      padding: 1rem 0;
    }
    .menu{
      width: 12rem;
      .title{
        position: relative;
        background-color: #f7f7f7;
        padding: 0.75rem 0;
        text-align: center;
        font-size: 20px;
        color: #333;
        border-bottom: 1px solid #e6e6e6;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        cursor: pointer;
        &::after{
          content: "›";
          position: absolute;
          top: 0.4rem;
          right: 0.6rem;
          color: #d4d4d4;
          font-size: 1.5rem;
        }
      }
      dl{
        width: 100%;
        min-height: 25rem;
        background-color: #fff;
        text-align: center;
        padding: 0 0 1rem 0;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        h5{
          position: relative;
          padding: 0.75rem 0;
          cursor: pointer;
          font-size: 18px;
          background-color: #f7f7f7;
          &::after{
            content: "›";
            position: absolute;
            top: 0.4rem;
            right: 0.75rem;
            font-size: 1.5rem;
            color: #d4d4d4;
            transform: rotate(90deg);
          }
        }
        p{
          padding: 0.7rem 0;
          font-size: 14px;
          margin-left: 0.7rem;
        }
        a{
          cursor: pointer;
          color: rgb(108, 108, 108);
          &:hover {
            color: $Active_Color;
          }
        }
      }
    }
    .main{
      margin-left: 1rem;
      overflow-y: hidden;
      width: 55.5rem;
      height: auto;
      .news_box {
        .news_title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          > div {
            display: flex;
            justify-content: flex-start;
            min-width: 130px;
            .C-Svg {
              width: 20px;
              height: 20px;
              margin-right: 5px;
              svg {
                height: 100%;
                width: 100%;
                fill: $Active_Color;
              }
            }
            span {
              align-self: center;
              font-size: 16px;
              font-weight: bolder;
            }
          }
          i {
            align-self: center;
            width: 100%;
            height: 1px;
            background: #ddd;
          }
        }
        h1{
          i{
            font-size: 0.12rem;
            text-decoration: underline;
            color: #ff4d4f;
            cursor: pointer;
          }
        }
        .titleDate{
          font-size: 0.12rem;
          margin: 0.5rem 0;
        }
        ul {
          li {
            display: flex;
            justify-content: flex-start;
            font-size: 14px;
            cursor: pointer;
            border-bottom: 1px solid #ddd;
            label {
              min-width: 140px;
              padding: 10px 0;
              // color: #4777f4;
            }
            p {
              //width: 100%;
              padding: 10px 0;
              line-height: 22px;
            }
            .detailInfo{
              margin-left: 10px;
              color: #ff4d4f;
            }
            &:hover {
              color: #ff4d4f;
              label {
                color: #ff4d4f;
              }
            }
          }
        }
      }
      .hot_box {
        ul li a {
          padding: 15px 0;
          border-top: 1px solid #ddd;
          display: flex;
          img {
            width: 240px;
            height: 168px;
            margin-right: 100px;
          }
          h6 {
            font-size: 18px;
            color: #333;
            margin-bottom: 30px;
          }
          p {
            font-size: 14px;
            color: $Gary_Color;
          }
        }
      }
      .calender_box {
        .ant-tabs-nav .ant-tabs-tab-active{
          color: $Active_Color !important;
        }
        .ant-tabs-nav .ant-tabs-tab:hover{
          color: $Active_Color;
        }
        .ant-tabs-ink-bar{
          background: $Active_Color;
        }
        ul{
          //width: 1000px;
          margin: auto;
          li{
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            height: 40px;
            line-height: 40px;
            &:first-child{
              border-top: 1px solid #f5f5f5;
            }
            div{
              width: 8%;
              padding-left: 10px;
              border: 1px solid #f5f5f5;
              border-left: none;
              border-top: none;
              text-align: center;
              &:first-child{
                border-left: 1px solid #f5f5f5;

              }
              &.info_main{
                width:35%;
                text-align: left;
              }
              &.important{
                width: 17%;
                text-align: left;
                img{
                  width: 60%;
                }
              }
              &.country{
                padding: 0;
                display: flex;
                justify-content: center;
                img{
                  width: 30px;
                  height: 30px;
                  align-self: center;
                }
              }
              b{
                height: 30px;
                padding: 0 8px;
                &.green{
                  border: 1px solid $FALL;
                  color: $FALL;
                }
                &.red{
                  border: 1px solid $RAISE;
                  color: $RAISE;
                }
              }
            }
            &.ul_title{
              height: 32px;
              line-height: 32px;
              color: $White;
              background: $Active_Color;
            }
            img{

            }
          }
        }
      }
      .more {
        width: 100%;
        height: 58px;
        line-height: 58px;
        text-align: center;
        border-radius: 3px;
        font-size: 14px;
        background: #f5f5f5;
        @include common_btn;
        &:hover {
          background: $Active_Color;
          color: $White;
        }
      }
      .school-header {
        padding: 0.75rem 0;
        font-size: 14px;
        line-height: 1rem;
        color: $Active_Color;
        padding-left: 0.25rem;
        a{
          color:  $Active_Color;
        }
        span {
          cursor: pointer;
        }
      }
      .school-index {
        width: 100%;
        padding: 1.25rem;
        border-radius: 0.25rem;
        background-color: #fff;
        overflow-y: hidden;
        .title{
          position: relative;
          background-color: #f7f7f7;
          font-size: 1rem;
          padding: 0.5rem 0;
          padding-left: 1.25rem;
          &::before{
            content: "";
            width: 0.1rem;
            background: #353f59;
            height: 0.75rem;
            border-radius: 0.15rem;
            left: 0.75rem;
            top: 0.6rem;
            position: absolute;
          }
        }
        .beginner{
          .column {
            margin: 2rem 0;
            display: flex;
            display: -webkit-flex;
            .itemleft {
              width: 48%;
              border-right: 1px solid #e6e6e6;
              padding-left: 0.5rem;
            }
            .itemright {
              width: 48%;
              margin-left: 4%;
            }
            .item{
              .subtitle {
                padding: 0.25rem 0 1.25rem 0;
                font-size: 0.9rem;
                a {
                  margin-left: 2rem;
                  font-size: 0.65rem;
                  line-height: 1.1rem;
                  color: #a8a7a4;
                }
              }
              .cell {
                display: flex;
                display: -webkit-flex;
                ol{
                  flex: 0.6;
                  -webkit-box-flex: .6;
                  -webkit-flex: .6;
                  padding-left: 1.1rem;
                  list-style-type: disc;
                  li{
                    padding: 0.25rem 0;
                    font-size: rem(16px);
                    &>a {
                      color: #333;
                      cursor: default
                    }
                  }
                }
                img{
                  flex: 0.25;
                  -webkit-box-flex: .25;
                  -webkit-flex: .25;
                }
              }
            }
          }
        }
        .process {
          width: 100%;
          .step{
            margin: 1.5rem 0 0.25rem 0;
            display: flex;
            display: -webkit-flex;
            .item{
              flex: 0.33;
              -webkit-box-flex: .33;
              -webkit-flex: .33;
              display: inline-flex;
              padding: 0.5rem 0;
              position: relative;
              h5{
                color: #a8a7a4;
                font-size: 3.35rem;
              }
              span{
                padding-left: 0.5rem;
                padding-top: 0.3rem;
                font-size: 1.6rem;
                line-height: 3.35rem;
                color: #d1a24c;
              }
            }
            .arrow{
              &::after{
                content: "›";
                position: absolute;
                top: 0px;
                right: 1.25rem;
                font-size: 3.9rem;
                color: #d4d4d4;
              }
            }
          }
        }
      }
    }
  }
  &.page0 .main{
    .school-guide {
      width: 100%;
      padding: 0.75rem 1.25rem;
      border-radius: 0.25rem;
      background-color: #fff;
      overflow-y: hidden;
      .title {
        padding: 0.5rem;
        background-color: #f7f7f7;
        font-size: 0.8rem;
        line-height: 1.4rem;
      }
      .view {
        width: 100%;
        padding: 0.5rem 0;
      }
    }
  }
  &.page3 .main{
    .school-list {
      width: 100%;
      padding-left: 1rem;
      background-color: #fff;
      border-radius: 0.25rem;
      min-height: 26.25rem;
      overflow: hidden;
      .back {
        display: none;
        width: 100%;
        padding-top: 1rem;
        text-align: center;
      }
    }
    .school-list-body {
      width: 100%;
      overflow-y: hidden;
      ul {
        list-style-type: none;
        li{
          font-size: 0.8rem;
          position: relative;
          padding: 0.9rem 0 1rem 0.25rem;
          border-bottom: 1px solid #d3d3d3;
          label{
            font-size: 0.85rem;
            padding: 0 8px;
            color: #989898;
          }
          div{
            display: inline-block;
            color: #181818;
            cursor: pointer;
          }
        }
      }
    }
    .school-detail {
      //width: 100%;
      padding: 0.75rem 1rem;
      background-color: #fff;
      border-radius: 0.25rem;
      min-height: 26.25rem;
      position: relative;
      overflow: hidden;
      .fh{
        position: absolute;
        right: 0.25rem;
        top: 0;
      }
      .school-detail-title {
        padding: 0.5rem 0 0.6rem 0;
        font-size: 1.2rem;
        font-weight: 900;
        border-bottom: 1px dashed #d3d3d3;
        color:#000;
      }
      .school-detail-content {
        color: #868585;
        padding-top: 0.75rem;
        font-size: rem(16px);
        p{
          margin-top: 0.5rem;
          line-height: 1.6rem;
        }
        .subtitle {
          font-weight: 600;
          padding: 0.25rem 0;
        }
      }
    }
  }
}
