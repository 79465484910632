@import "../../../style/variable";

#FundsDetail{
  @include column;
  flex: 1;
  width: 100%;
  height: 100%;
  .ant-tabs-bar{
    margin-bottom: 5px;
  }
  .funds_title {
    height: 93px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    background: $White;
    @include boxShadow;
    h5 {
      align-self: center;
      font-size: rem(32px);
      line-height: 38px;
    }
  }
  .funds_main{
    flex: 1;
    margin-top: 10px;
    background: $White;
    padding: 24px;
    @include boxShadow;
    .ant-tabs-nav-container{
      .ant-tabs-nav .ant-tabs-tab{
        padding: 12px 0;
        &:hover{
          color: $Active_Color;
        }
        &.ant-tabs-tab-active{
          color: $Active_Color;
        }
      }
      .ant-tabs-ink-bar{
        background: $Active_Color;
      }
    }
  }
  .title{
    display: flex;
    height: 49px;
    .dateBox {
      display: flex;
      color: $Font_Color;
      font-size: 12px;
      margin-right: 20px;
      .subtitle {
        color: $Deep_Section_Bg;
        width: rem(50px);
        line-height: rem(57px);
      }
      .dateWrapper{
          display: flex;
          span{
            align-self: center;
            &.line{
              width: 30px;
              text-align: center;
            }
          }
        }
      .ant-calendar-picker-input {
        color: $Black_Font_Color;
        &::placeholder {
          color: $Font_Color;
        }
      }
      .ant-calendar-picker-icon {
        color: $Gary_Color;
      }
      .ant-calendar-picker-input.ant-input {
        background: transparent;
        border-color: #f5f5f5;
        font-size: 12px;
      }
    }
    .searchButtonGroup {
      display: flex;
      align-self: center;
      .quickSearchBox {
        align-self: center;
        .quickSearch, .searchButton {
          width: rem(60px);
          text-align: center;
          height: rem(38px);
          line-height: rem(38px);
          border: 1px solid #f5f5f5;
          color: $Font_Color;
          margin-right: rem(15px);
          border-radius: 4px;
          font-size: 12px;
          cursor: pointer;
        }
        .quickSearch:hover {
          background: $Active_Color;
          border-color: $Active_Color;
          color: $White;
        }
        .searchButton {
          width: rem(80px);
          background: $Active_Color;
          color: $White;
          margin: 0;
        }
        .active{
          background: $Active_Color;
          color: $White;
        }
      }
    }
    .ant-select{
      width: 150px;
      align-self: center;
      margin-right: 20px;
      .ant-select-selection{
        border: 1px solid #f5f5f5;
        &:focus{
          box-shadow: none;
        }
      }
      .ant-select-selection-selected-value{
        display: flex!important;
        height: 32px;
        img{
          align-self: center;
          margin: 5px;
          width: 20px;
          height: 20px;
        }
        span{
          align-self: center;
        }
      }
    }
  }
}