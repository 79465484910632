@import "../../../style/variable";

#Withdraw {
  .withdraw_title {
    height: 93px;
    padding: 24px;
    display: flex;
    background: $White;
    @include boxShadow;
    h5 {
      align-self: center;
      font-size: rem(32px);
      line-height: 38px;
    }
  }
  .withdraw_main {
    padding: 24px;
    background: $White;
    margin: 10px 0;
    @include boxShadow;
    .switch_btn {
      display: flex;
      justify-content: space-between;
      height: 46px;
      line-height: 46px;
      @include common_btn;
      border-bottom: 1px solid #f5f5f5;
      div {
        border-bottom: 2px solid $Active_Color;
      }
      a {
        font-size: 12px;
        color: $Active_Color;
      }
    }
    .withdraw_info {
      display: flex;
      padding-top: 25px;
      > div {
        flex: 1;
        .simple_input .input_title a span {
          color: $Black_Font_Color;
          b {
            color: $Active_Color;
            display: inline-block;
            margin-left: 10px;
          }
        }
        .confirm_btn {
          margin-top: 25px;
        }
        &.tips {
          display: flex;
          justify-content: flex-end;
          div {
            align-self: flex-end;
            h6 {
              font-size: 14px;
              line-height: 20px;
            }
            p {
              font-size: 12px;
              color: $Gary_Color;
              line-height: 20px;
              align-self: flex-start;
            }
          }
        }
      }
      .forgot {
        display: flex;
        justify-content: flex-end;
        div {
          font-size: 12px;
          color: $Active_Color;
          @include common_btn;
        }
      }
    }
  }

  .withdraw_record {
    @include boxShadow;
    height: 374px;
    padding: 24px;
    background: $White;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .record {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f5f5f5;
      user-select: none;
      h5 {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        border-bottom: 2px solid $Active_Color;
      }
      a {
        font-size: 12px;
        color: $Active_Color;
        align-self: center;
      }
    }
    ul li {
      display: flex;
      justify-content: space-between;

      font-size: rem(14px);
      padding: 0 16px;
      &.record_title {
        background: #f5f5f5;
        font-size: 12px;
        color: $Gary_Color;
      }
      > div {
        flex: 1;
        height: 44px;
        line-height: 44px;
        display: flex;
        &.status {
          justify-content: center;
        }
        .cancel {
          align-self: center;
          font-size: 12px;
          height: 24px;
          line-height: 14px;
          padding: 5px 10px;
          text-align: center;
          border-radius: 2px;
          color: $White;
          background: $Active_Color;
          @include common_btn;
        }
      }
    }
  }
}
