@import './common.scss';
@import "~antd/dist/antd.css";
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { margin:0; padding:0; } 
body, button, input, select, textarea { font:12px/1.5, arial, \5b8b\4f53; } 
h1, h2, h3, h4, h5, h6{ font-size:100%; } 
address, cite, dfn, em, var { font-style:normal; } 
code, kbd, pre, samp { font-family:couriernew, courier, monospace; } 
small{ font-size:12px; } 
ul, ol { list-style:none; } 
a { text-decoration:none; } 
a:hover { text-decoration:none; } 
sup { vertical-align:text-top; } 
sub{ vertical-align:text-bottom; } 
legend { color:#000; } 
fieldset, img { border:0; } 
button, input, select, textarea { font-size:100%; } 
table { border-collapse:collapse; border-spacing:0; }

html,body{
    height: 100%;
    background: #fff;
}

#root{
    height: 100%;
    width:100%;
    background: #fff;
    // overflow: hidden;
}

::-webkit-scrollbar {
    display: none;
}

@include screen(1080);
@include screen(960);
@include screen(750);
@include screen(640);
@include screen(600);
@include screen(540);
@include screen(480);
@include screen(414);
@include screen(400);
@include screen(375);  