@import "../../../style/variable";

.tradeOrder,
.tradeLeverOrder {
  width: 354px;
  @include column;
  justify-content: flex-start;
  .order_box {
    @include column;
    justify-content: flex-start;
    margin-left: 10px;
    .nav_box {
      display: flex;
      justify-content: space-between;
      height: 44px;
      line-height: 44px;
      background: #151515;
      > div {
        display: flex;
      }
      .title {
        width: 100%;
        > div {
          flex: 1;
          color: $White;
          text-align: center;
          @include common_btn;
          font-size: 14px;
          &.active {
            border-top: 2px solid $Active_Color;
            background: #202020;
          }
        }
      }
    }
    .order_info {
      @include column;
      .dynamic {
        padding: 16px 16px 20px;
        background: $Deep_Section_Bg;
        margin-bottom: 1px;
        .dynamic_info {
          display: flex;
          > div {
            display: flex;
            flex: 1;
            justify-content: space-between;
            color: $Gary_Color;
            font-size: 12px;
            b {
              margin-left: 5px;
            }

            &:first-child {
              padding-right: 8px;
              b {
                color: $RAISE;
              }
            }
            &:last-child {
              padding-left: 8px;
              b {
                color: $FALL;
              }
            }
          }
        }
        .volume_info {
          position: relative;
          margin-top: 8px;
          div {
            position: absolute;
            height: 5px;
            &.buy {
              left: 0;
              background: $RAISE;
            }
            &.sell {
              right: 0;
              background: $FALL;
            }
          }
        }
      }
      .line {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px;
        margin-bottom: 1px;
        background: $Deep_Section_Bg;
        .ant-checkbox-checked::after {
          border: $Active_Color;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: $Active_Color;
          border-color: $Active_Color;
        }
        .ant-checkbox-inner {
          border: $Active_Color;
        }
        label {
          font-size: 14px;
          align-self: center;
          color: $Gary_Color;
          display: flex;
          img {
            align-self: center;
            width: 14px;
            height: 14px;
            margin-left: 5px;
          }
        }
        .volume {
          display: flex;
          > div {
            font-size: rem(14px);
          }
          .deduct {
            color: $White;
            font-size: 12px;
            align-self: center;
            margin-right: 5px;
          }
          .ant-switch-checked {
            background: $Active_Color;
          }
          .input_box {
            display: flex;
            justify-content: space-between;
            // border: 1px solid $Gary_Color;
            border-radius: 3px;
            height: 32px;
            // padding: 0 5px;
            width: 202px;
            input {
              background: transparent;
              border: none;
              box-shadow: none;
              outline: none;
              font-size: 14px;
              width: 100px;
              color: $Active_Color;
            }
            .btnBox {
              display: flex;
              .btn {
                margin-left: 10px;
                width: 20px;
                height: 20px;
                align-self: center;
              }
            }
            &:hover {
              border-color: $Active_Color;
            }
            &.focus {
              border-color: $Active_Color;
            }
          }
        }
        &.one {
          .volume {
            div {
              width: 65px;
              height: 24px;
              line-height: 22px;
              text-align: center;
              border: 1px solid $Gary_Color;
              color: $Gary_Color;
              margin-left: 15px;
              border-radius: 2px;
              @include common_btn;
              &.active {
                border-color: $Active_Color;
                color: $Active_Color;
              }
            }
          }
        }
        &.two {
          @include column;
          label {
            align-self: flex-start;
          }
          .volume {
            margin-top: 10px;
            div {
              padding: 0 10px;
              height: 24px;
              line-height: 22px;
              text-align: center;
              border: 1px solid $Gary_Color;
              margin-right: 10px;
              color: #c0c2cb;
              border-radius: 2px;
              @include common_btn;
              &.active {
                color: $Active_Color;
                border-color: $Active_Color;
              }
              &:last-child {
                margin: 0;
              }
            }
          }
        }
        &:first-child {
          padding-top: 22px;
        }
      }
      .slsp_set {
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        background: $Deep_Section_Bg;
        margin-bottom: 1px;
        height: 45px;
        label {
          flex: 0.15;
          font-size: 12px;
          color: $Gary_Color;
          align-self: center;
        }
        .slsp_slider {
          flex: 0.85;
          display: flex;
          justify-content: space-between;
          b {
            width: 60px;
            text-align: center;
            line-height: 45px;
            color: #c0c2cb;
          }
          > div {
            align-self: center;
            width: 100%;
            display: flex;
            img {
              width: 20px;
              height: 20px;
            }
            .ant-slider {
              align-self: center;
              width: 100%;
              margin: 0 10px;
            }
          }
        }
      }
      .total {
        background: $Deep_Section_Bg;
        margin-bottom: 1px;
        > div {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          padding: 10px 16px;
          label {
            color: $Gary_Color;
          }
          div {
            color: $Gary_Color;
          }
          &:last-child {
            font-size: 14px;
            div {
              font-size: 20px;
              color: $Active_Color;
            }
          }
        }
      }
      .btn_box {
        margin-top: 1px;
        display: flex;
        justify-content: space-between;
        .btn {
          width: 45%;
          height: 50px;
          font-size: 14px;
          color: $White;
          @include common_btn;
          @include column;
          position: relative;
          text-align: center;
          justify-content: center;
          b {
            margin-top: 5px;
          }
          &:first-child {
            border-radius: 2px 0 0 2px;
            &::before {
              position: absolute;
              right: -24px;
              top: 0;
              content: "";
              width: 0;
              height: 0;
              border-top: 25px solid $RAISE;
              border-right: 12px solid transparent;
              border-bottom: 25px solid transparent;
              border-left: 12px solid $RAISE;
              border-top-right-radius: 3px;
              z-index: 1;
            }
          }
          &:last-child {
            border-radius: 0 3px 3px 0;
            &::before {
              position: absolute;
              left: -24px;
              top: 0;
              content: "";
              width: 0;
              height: 0;
              border-top: 25px solid transparent;
              border-right: 12px solid $FALL;
              border-bottom: 25px solid $FALL;
              border-left: 12px solid transparent;
              border-bottom-left-radius: 3px;
              z-index: 1;
            }
          }
        }
      }
    }
  }
  .rule {
    flex: 1;
    background: $Deep_Section_Bg;
    margin-top: 10px;
    margin-left: 10px;
    &.short {
      max-height: 513px;
    }
    &.high {
      max-height: 573px;
    }
  }
}
.order_confirm {
  width: 400px !important;
  .ant-modal-close-x svg {
    fill: $White;
  }
  .ant-modal-header {
    background: #1b1d26;
    border: none;
    .ant-modal-title {
      color: $White;
    }
  }
  .ant-modal-body {
    background: #121319;
    padding: 10px 10px 20px;
    > div {
      display: flex;
      justify-content: space-between;
      color: $White;
      height: 34px;
      line-height: 34px;
      padding: 0 25px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      label {
        font-size: 12px;
        color: #c0c2cb;
      }
      b {
        font-size: 12px;
        font-weight: normal;
        &.total {
          font-size: 14px;
          color: $Active_Color;
          font-weight: bolder;
        }
      }
      &.btn_box {
        border: none;
        height: auto;
        margin-top: 15px;
        div {
          width: 45%;
          text-align: center;
          background: #282b33;
          color: #c0c2cb;
          border-radius: 2px;
          @include common_btn;
          &:last-child {
            background: $Active_Color;
            color: $Black_Font_Color;
          }
        }
      }
      &.errorMessage {
        height: -webkit-fill-available;
        border-bottom: none !important;
        div {
          width: -webkit-fill-available;
          text-align: center;
        }
      }
    }
  }
  .ant-modal-footer {
    padding: 0;
    border: none;
  }
}

.tradeLeverOrder {
  width: 354px;
  @include column;
  justify-content: flex-start;
  .lever_box {
    background: $Deep_Section_Bg;
    padding: 12px 16px;
    @include column;
    .lever_title {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: $Gary_Color;
      b {
        color: $Active_Color;
      }
      > div {
        @include common_btn;
        display: flex;
        i {
          align-self: center;
        }
        .C-Svg {
          width: 13px;
          height: 13px;
          margin-left: 3px;
          svg {
            width: 100%;
            height: 100%;
            fill: $Gary_Color;
          }
        }
        &:hover {
          color: $Active_Color;
          .C-Svg svg {
            fill: $Active_Color;
          }
        }
      }
    }
    .ant-slider {
      .ant-slider-rail {
        background: #575757;
      }
      .ant-slider-track {
        background: $Active_Color;
      }
      .ant-slider-step {
        .ant-slider-dot {
          background: #575757;
          border: $Deep_Gary_Color;
          &.ant-slider-dot-active {
            background: $Active_Color;
          }
        }
      }
      .ant-slider-handle {
        background: $Active_Color;
        border: $Active_Color;
      }
      .ant-slider-mark {
        .ant-slider-mark-text {
          color: $Gary_Color;
          &.ant-slider-mark-text-active {
            color: $White;
          }
        }
      }
    }
  }
  .profit_box {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    background: $Deep_Section_Bg;
    margin-top: 1px;
    font-size: 12px;
    color: $Gary_Color;
    label {
      flex: 0.2;
      height: 24px;
      line-height: 24px;
    }
    > div {
      flex: 0.8;
      input {
        width: 90%;
        background: transparent;
        border: none;
        outline: none;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        border-radius: 2px;
        color: $White;
      }
      .proportion {
        display: flex;
        margin-top: 8px;
        justify-content: space-between;
        div {
          border: 1px solid $Gary_Color;
          width: 50px;
          height: 24px;
          line-height: 22px;
          border-radius: 2px;
          text-align: center;
          color: #c0c2cb;
          @include common_btn;
          &.active {
            border-color: $Active_Color;
            color: $Active_Color;
          }
          &:hover {
            border-color: $Active_Color;
            color: $Active_Color;
          }
        }
      }
    }
  }
  .choose_box {
    display: flex;
    @include column;
    padding: 12px 16px;
    background: $Deep_Section_Bg;
    margin-top: 1px;
    font-size: 12px;
    label {
      color: $Gary_Color;
    }
    .proportion {
      display: flex;
      margin-top: 8px;
      justify-content: space-between;
      div {
        border: 1px solid $Gary_Color;
        width: 50px;
        height: 24px;
        line-height: 22px;
        border-radius: 2px;
        text-align: center;
        color: #c0c2cb;
        @include common_btn;
        &:hover {
          border-color: $Active_Color;
          color: $Active_Color;
        }
        &.active {
          border-color: $Active_Color;
          color: $Active_Color;
        }
      }
    }
  }
  .total {
    margin-top: 1px;
  }
}

.traderSettingModal {
  .ant-modal-header {
    .ant-modal-title {
      text-align: center;
      font-size: 18px;
    }
  }
  .ant-modal-body {
    padding: 0;
    .ant-slider {
      .ant-slider-rail {
        background: #575757;
      }
      .ant-slider-track {
        background: $Active_Color;
      }
      .ant-slider-step {
        .ant-slider-dot {
          background: #575757;
          border: $Deep_Gary_Color;
          &.ant-slider-dot-active {
            background: $Active_Color;
          }
        }
      }
      .ant-slider-handle {
        background: $Active_Color;
        border: $Active_Color;
      }
      .ant-slider-mark {
        .ant-slider-mark-text {
          color: $Gary_Color;
          &.ant-slider-mark-text-active {
            color: $White;
          }
        }
      }
    }
  }
  .set_title {
    width: 100%;
    color: $Gary_Color;
    font-size: 12px;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    display: flex;
    justify-content: space-between;
    span {
      align-self: center;
    }
    b {
      font-size: 20px;
      color: $White;
    }
  }
  .set_box {
    @include column;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    .slider_top {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        @include common_btn;
        img {
          align-self: center;
        }
      }
      .ant-slider {
        width: 80%;
      }
    }
    .slider_ {
      display: flex;
      justify-content: space-between;

      div {
        color: $White;
      }
    }
  }
  .set_line {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    label {
      color: $White;
      font-size: 12px;
    }
    .ant-switch-checked {
      background: $Active_Color;
    }
  }
  .set_btn {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    div {
      @include common_btn;
      width: 45%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #282b33;
      color: $White;
      border-radius: 3px;
      &:last-child {
        background: $Active_Color;
        color: $Deep_Gary_Color;
      }
    }
  }
}

.ant-tooltip-inner {
  font-size: 12px !important;
  color: $Active_Color !important;
}
.ant-message-notice-content {
  position: relative;
  top: 225px;
}
