@import "../../../style/variable";

#Detail{
  width: 100%;
  ol{
    width: 100%;
    height: 484px;
    li{
      display: flex;
      height: 44px;
      line-height: 44px;
      justify-content: space-between;
      padding: 0 16px;
      font-size: 14px;
      border-bottom: 1px solid #f5f5f5;
      &.li_title{
        background: #f5f5f5;
        color: $Gary_Color;
        font-size: 12px;
      }
      div{
        flex: 1;
        &.status{
          text-align: center;
        }
        &:last-child{
          text-align: right;
        }
      }
    }
    &.crypto_ul{
      li{
        &.li_title{
          font-size: 14px;
        }
        div{
          flex: none;
          width: 12%;
          font-size: 12px;
        }
        .longer{
          width: 40%;
        }
      }
    }
  }
  .trade_ol{
    li{
      div{
        flex: none;
        width: 10.4%;
      }
      .long{
        width: 12%;
      }
      .longer{
        width: 18%;
      }
      .income{
        padding-left: 30px;
      }
    }
  }
  .eagle_ol li div{
    &:last-child{
      text-align: left;
    }
  }
  .ant-select {
    width: 100%;
    align-self: center;
    .ant-select-selection {
      border: 1px solid #f5f5f5;
      height: rem(38px);
      line-height: rem(38px);
      &:focus {
        box-shadow: none;
      }
      .ant-select-selection__rendered {
        line-height: rem(38px);
        display: flex;
      }
    }
    .ant-select-selection-selected-value {
      display: flex !important;
      height: 30px;
      align-self: center;
      img {
        align-self: center;
        margin: 5px;
        width: 20px;
        height: 20px;
      }
      b {
        align-self: center;
      }
      span {
        align-self: center;
      }
      .default {
        display: flex;
      }
    }
  }
}

