@import "../../../style/variable";

#Invite {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: auto;
  background: #fafafa;
  #Header {
    background: rgba(0, 0, 0, .3);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .banner_box {
    height: 366px;
    width: 100%;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    div {
      position: relative;
      z-index: 1;
      width: $Max_Width;
      height: 100%;
      margin: auto;
      @include column_center;
    }
    h5 {
      height: 67px;
      font-size: 48px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: $White;
      line-height: 67px;
    }
    p {
      color: $White;
      font-size: 14px;
      b {
        font-size: 40px;
        color: $Active_Color;
      }
    }
  }
  main {
    margin: auto;
    width: $Max_Width;
    height: auto;
    .section {
      height: 360px;
      background: $White;
      @include boxShadow;
      margin-top: -85px;
      margin-bottom: 15px;
      h6 {
        height: 85px;
        font-size: 32px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: $Black_Font_Color;
        line-height: 45px;
        padding: 40px 40px 0;
      }
      .step_box {
        padding: 0 40px 40px;
        display: flex;
        justify-content: space-between;
        div {
          @include column;
          img {
            width: 300px;
            height: 140px;
            margin: 32px 0 15px;
          }
          b {
            font-size: 18px;
            color: $Black_Font_Color;
          }
          p {
            font-size: 14px;
            color: $Gary_Color;
            margin-top: 6px;
          }
        }
      }
    }
    .invite_box {
      height: 465px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .left{
        width: 100%;
        //margin-right: 32px;
        background: $White;
        @include boxShadow;
        padding: 40px;
        @include column;
        .title{
          div{
            height: 45px;
            font-size: 32px;
            font-weight: 600;
            text-align: left;
            color: $Black_Font_Color;
            line-height: 45px;
          }
          p{
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: $Gary_Color;
            line-height: 20px;
          }
        }
        .invite_link{
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: $Gary_Color;
          @include column;
          label{
            display: block;
            margin-bottom: 10px;
          }
          .code_box{
            display: flex;
            .copy{
              width: 120px;
              height: 50px;
              border-radius: 2px;
              text-align: center;
              line-height: 50px;
              margin-left: 30px;
              color: $White;
              background: $Active_Color;
              @include common_btn;
            }
          }
          .link{
            display: flex;
            justify-content: flex-start;
            height: 50px;
            line-height: 50px;
            padding: 0 30px;
            width: auto;
            color: $Black_Font_Color;
            background: #f5f5f5;
          }
        }
        .copy_link{
          width: 630px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          border-radius: 2px;
          color: $White;
          background: $Active_Color;
          @include column;
        }

      }
      //.right{
      //  flex: 1;
      //  background: $White;
      //  @include boxShadow;
      //  padding: 40px;
      //  .title{
      //    div{
      //      height: 45px;
      //      font-size: 32px;
      //      font-weight: 600;
      //      text-align: left;
      //      color: $Black_Font_Color;
      //      line-height: 45px;
      //    }
      //  }
      //  .qrCode{
      //    width: 200px;
      //    height: 200px;
      //    display: block;
      //    margin: 60px auto;
      //    canvas{
      //      width: 100% !important;
      //      height: 100%!important;
      //    }
      //  }
      //}
    }
    .proxy_box{
      height: 224px;
      padding: 40px;
      width: 100%;
      background: $White;
      margin-bottom: 13px;
      @include boxShadow;
      .title{
        height: 45px;
        font-size: 32px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: $Black_Font_Color;
        line-height: 45px;
        margin-bottom: 30px;
      }
      .info{
        display: flex;
        >div{
          flex: 1;
          label{
            font-size: 14px;
            color: $Gary_Color;
            display: block;
            margin-bottom: 10px;
          }
          div{
            line-height: 50px;
            height: 50px;
            font-size: 32px;
            color: $Active_Color;
            display: flex;
            img{
              width: 40px;
              align-self: center;
            }
          }
        }
      }
    }

    .invite_trade{
      background: $White;
      @include boxShadow;
      margin-bottom: 10px;
      padding: 40px;
      .title{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        h5{
          font-size: 32px;
          font-weight: 600;
          color: $Black_Font_Color;
        }
        a{
          color: $Active_Color;
          font-size: 12px;
          align-self: flex-end;
        }
      }
      ol{
        li{
          display: flex;
          height: 44px;
          line-height: 44px;
          border-bottom: 1px solid #f5f5f5;
          padding:0 12px;
          font-size: 14px;
          div{
            width: 22.5%;
            text-align: center;
            &:first-child{
              width: 10%;
            }
          }
          &.li_title{
            background: #f5f5f5;
            color: $Gary_Color;
            font-size: 12px;
          }
        }
        .empty{
          height: 400px;
        }
      }
    }

    .level_box{
      padding: 40px;
      background: $White;
      @include boxShadow;
      margin-bottom: 70px;
      .title{
        display: flex;
        justify-content: space-between;
        .left{
          div{
            height: 45px;
            font-size: 32px;
            font-weight: 600;
            text-align: left;
            color: $Black_Font_Color;
            line-height: 45px;
          }
          p{
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: $Gary_Color;
            line-height: 20px;
          }
        }
        .right{
          a{
            display: block;
            width: 200px;
            height: 45px;
            text-align: center;
            line-height: 45px;
            border-radius: 3px;
            background: $Active_Color;
            color: $White;
          }
        }
      }
      .level{
        display: flex;
        padding: 60px 0;
        div{
          flex: 1;
          @include column;
          img{
            width: 90px;
            height: 117px;
            margin: 20px auto;
          }
          b{
            font-size: 18px;
            text-align: center;
          }
          span{
            text-align: center;
            font-size: 20px;
          }
          p{
            font-size: 14px;
            margin-top: 10px;
            text-align: center;
          }
        }
      }
    }
  }
}