@import "../../style/variable";

#Home {
  width: 100%;
  height: 100%;
  font-size: 0;

  .Section1 {
    width: 100%;
    position: relative;
    .ant-carousel .slick-dots li button {
      width: 50px;
      height: 5px;
      border-radius: 4px;
    }
    .bannerImg {
      cursor: pointer;
      width: 100%;
    }
    .dot {
      outline: none;
    }
    .title {
      background: #202020;
      width: 100%;
      height: 30px;

      .title_main {
        width: $Max_Width;
        display: flex;
        justify-content: space-between;
        margin: auto;
        height: 100%;

        .ex {
          display: flex;

          > div {
            display: flex;

            img {
              align-self: center;
              width: 21px;
              height: 13px;

              svg {
                width: 100%;
                height: 100%;
              }
            }

            span {
              align-self: center;
              font-size: 12px;
              padding: 0 rem(25px) 0 rem(6px);

              b {
                color: $Font_Color;
                display: inline-block;
                padding-right: rem(5px);
              }

              i {
                color: $Light_Font_Color;

                &.open {
                  color: $FALL;
                }
              }
            }
          }
        }

        .center {
          display: flex;
          align-self: center;
          @include common_btn;

          .C-Svg {
            align-self: center;
            width: 15px;
            height: 18px;
          }

          span {
            align-self: center;
            font-size: 14px;
            color: $White;
            padding-left: 5px;
          }
        }
      }
    }

    .header {
      width: 100%;
      height: rem(66px);
      background: #151515;

      .header_main {
        width: $Max_Width;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: space-between;

        img {
          align-self: center;
          height: 100%;
          padding: rem(10px) 0;
        }

        .nav_box {
          display: flex;
          align-self: center;
          height: 100%;

          > div {
            margin-left: 30px;
            height: 100%;
            display: flex;

            a {
              @include common_btn;
              align-self: center;
              height: 100%;
              line-height: 58px;
              color: $White;
              font-size: 14px;

              span {
                display: block;
                line-height: 58px;
              }

              &.active {
                color: $Active_Color;
              }

              &:hover {
                color: $Active_Color;
              }
            }
          }

          > a {
            align-self: center;
            display: inline-block;
            @include common_btn;
            width: 130px;
            height: 38px;
            background: linear-gradient(90deg, #ff0002, #f78c1c);
            border-radius: 4px;
            text-align: center;
            line-height: 38px;
            color: $White;
            margin-left: 25px;
            font-size: 16px;
          }
        }
      }
    }

    > a {
      img {
        width: 100%;
      }
    }

    .slick-slider {
      .slick-track {
        display: flex;
      }

      .banner-dots {
        display: flex !important;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 10;
        margin-bottom: rem(60px);

        li {
          list-style: none;

          &.slick-active {
            .dot {
              background: $Active_Color;
            }
          }

          .dot {
            @include common_btn();
            width: rem(40px);
            height: rem(6px);
            border-radius: 4px;
            background: #d8d8d8;
            margin: 0 12px;
          }
        }
      }
    }
  }

  .Section2 {
    width: 100%;
    height: 726px;
    position: relative;

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .main {
      width: $Max_Width;
      margin: auto;
      padding-top: 80px;
      position: relative;
      z-index: 1;

      .title {
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        color: #151515;
        line-height: 56px;
        margin-bottom: 40px;
      }

      .list {
        display: flex;
        justify-content: space-between;

        .goods_info {
          position: relative;
          width: 278px;
          height: 400px;
          background: $White;
          border-radius: 4px;
          box-shadow: 0px 2px 15px 0px rgba(102, 102, 102, 0.15);
          color: $Black_Font_Color;
          padding: 0 40px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .code {
            position: absolute;
            left: 120px;
            top: -19px;
            width: 38px;
            height: 38px;
          }

          .name {
            font-size: 24px;
            text-align: center;
            margin: 48px 0 10px;
          }

          .contract {
            font-size: 16px;
            text-align: center;
          }

          .price {
            margin-top: 40px;
            display: flex;
            justify-content: center;

            div {
              align-self: center;
              position: relative;

              b {
                font-size: 48px;
              }

              .C-Svg {
                justify-content: flex-start;
                position: absolute;
                right: -20px;
                top: 8px;
              }
            }
          }

          .rate {
            display: flex;
            justify-content: space-between;
            font-size: 22px;
            padding: 15px 10px 30px;

            &.rest {
              font-size: 14px;
              display: flex;
              justify-content: center;
            }
          }

          .go_trade {
            border-top: 1px solid #eaecef;

            a {
              display: block;
              width: 151px;
              height: 44px;
              background: linear-gradient(90deg, #ff0002, #f78c1c);
              border-radius: 5px;
              margin: 30px auto;
              color: $White;
              font-size: 14px;
              text-align: center;
              line-height: 44px;
            }

            &.rest {
              a {
                background: #f5f5f5;
                color: $Gary_Color;
              }
            }
          }
        }
      }

      .more {
        display: flex;
        justify-content: flex-end;
        padding-top: 54px;

        a {
          font-size: 16px;
          font-weight: bolder;
          color: $RAISE;
        }
      }
    }
  }

  .Section3 {
    width: 100%;

    .introduction1,
    .introduction2 {
      position: relative;
      padding-top: 80px;
      height: 774px;
      background-size: 115%;

      .terminal {
        position: relative;
        z-index: 1;
        display: block;
        width: $Max_Width;
        margin: auto;
      }
    }

    .bg1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .introduction2 {
      background: url("../../assets/images/home/section2_bg.png") no-repeat
        center;
      height: 889px;
      padding-top: 110px;
    }

    .download {
      width: 100%;
      height: 717px;
      background: linear-gradient(45deg, #ff0002, #f78c1c);

      .download_main {
        width: $Max_Width;
        display: flex;
        justify-content: center;
        margin: auto;
        padding-top: 110px;

        .terminal {
          width: 650px;
          height: 100%;
        }

        .download_info {
          margin-left: 160px;

          h5 {
            font-size: 48px;
            font-weight: 600;
            color: $White;
            letter-spacing: 1px;
            padding-top: 100px;
          }

          p {
            width: 450px;
            font-size: 14px;
            color: $White;
            line-height: 26px;
            padding: 20px 0;
          }

          div {
            display: flex;

            > img {
              width: 160px;
              height: 48px;
              margin: 0 20px 20px 0;
            }

            .qrCode {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }
  }

  .Section4 {
    .license {
      padding: 80px 0;
      display: flex;

      .license_main {
        width: $Max_Width;
        margin: auto;
        display: flex;
        justify-content: space-between;

        .license_left {
          width: -webkit-fill-available;
          .license_top {
            align-self: center;
            font-size: 38px;
            color: #333333;
            line-height: 53px;
            letter-spacing: 1px;

            p {
              font-size: 16px;
              color: #000000;
              line-height: 26px;
              margin: 40px 0;
            }
          }

          .license_bottom {
            display: flex;
            margin-bottom: 30px;
            justify-content: center;
            align-items: center;

            .list_box {
              border: 1px solid #d7d7d7;
              border-radius: 2px;
              width: 328px;
              height: 154px;
              &:last-child {
                margin-left: 20px;
              }

              .list_top {
                padding: 20px 0;
                display: flex;
                height: 112px;
                border-bottom: 1px solid #d7d7d7;
                > div {
                  flex: 0.45;
                  display: flex;
                  .logo {
                    align-self: center;
                    height: 45px;
                    margin: auto;

                    &.msb {
                      height: 70px;
                    }

                    &.cr {
                      height: 50px;
                    }
                  }

                  &.info {
                    flex: 0.55;
                    @include column;
                    div {
                      display: flex;
                      font-size: 12px;
                      height: 24px;
                      line-height: 24px;
                      > img {
                        align-self: center;
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                      }

                      label,
                      b {
                        align-self: center;
                      }
                    }
                  }
                }
              }

              .list_btm {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                padding: 0 15px;
                p {
                  line-height: 40px;
                }

                a {
                  padding: 8px 0;
                  align-self: center;
                  color: $Black_Font_Color;
                  img {
                    width: 100px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }

        .license_right {
          flex: 0.45;
          height: 100%;
          margin-left: 20px;
          .title_img {
            display: flex;
            justify-content: center;
            margin: 40px 0;
            img {
              width: 58px;
              height: 58px;
              margin: 0 15px;
            }
          }
          .img_box {
            display: flex;
            .magnifier {
              align-self: center;
              margin: auto;
            }
            &.nfa {
              margin-top: 80px;
            }
          }
        }
      }
    }

    .partner {
      width: 100%;
      height: 783px;
      background: linear-gradient(45deg, #ff0002, #f78c1c);

      .partner_main {
        padding-top: 60px;
        width: $Max_Width;
        margin: auto;

        h5 {
          font-size: 38px;
          color: $White;
          line-height: 53px;
          letter-spacing: 1px;
          margin-bottom: 40px;
          text-align: center;
        }

        .global {
          position: relative;

          img {
            width: 100%;
          }

          a {
            position: absolute;
            width: 170px;
            height: 70px;

            &.cme {
              top: 0;
              left: 0;
            }

            &.ice {
              bottom: 200px;
              left: 0;
            }

            &.ny {
              bottom: 100px;
              left: 0;
            }

            &.co {
              bottom: 0;
              left: 0;
            }

            &.lme {
              top: 0;
              left: 210px;
            }

            &.zz {
              top: 0;
              left: 420px;
            }

            &.sh {
              top: 0;
              left: 630px;
            }

            &.dl {
              top: 0;
              left: 840px;
            }

            &.ine {
              top: 100px;
              right: 0;
            }

            &.jpx {
              top: 200px;
              right: 0;
            }

            &.cff {
              top: 300px;
              right: 0;
            }

            &.hk {
              top: 400px;
              right: 0;
            }

            &.eu {
              bottom: 0;
              left: 210px;
            }

            &.ex {
              bottom: 0;
              left: 420px;
            }

            &.mlxy {
              bottom: 0;
              left: 630px;
            }

            &.sg {
              bottom: 0;
              left: 840px;
            }
          }
        }
      }
    }
  }
}

.home_Qr {
  width: 150px;
  height: 150px;
  background: $White;
  img {
    width: 100%;
    height: 100%;
  }
}

.download_Qr {
  width: 110px;
  height: 110px;
  background: $White;
  img {
    width: 110px;
    height: 110px;
  }
}
