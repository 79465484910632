@import "@/style/common.scss";


.modal {
	width: 528px;
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5;
	list-style: none;
	position: relative;
	pointer-events: none;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	z-index: 10002;
	border: 0;
	border-radius: 4px;
	animation: flyIn 0.2s ease-in-out;
	animation-fill-mode: forwards;

	@keyframes flyIn {
		0% {
			transform: translate(-25%, -25%);
			opacity: 0;
			scale: 0;
		}
		100% {
			transform: translate(-50%, -50%);
			opacity: 1;
			scale: 1;
		}
	}
	.content {
		position: relative;
		background-color: #fff;
		background-clip: padding-box;
		border: 0;
		border-radius: 4px;
		-webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
		pointer-events: auto;
		padding: 0 24px;
		padding-bottom: 20px;
		.close {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 10;
			padding: 0;
			color: rgba(0, 0, 0, 0.45);
			font-weight: 700;
			line-height: 1;
			text-decoration: none;
			background: transparent;
			border: 0;
			outline: 0;
			cursor: pointer;
			-webkit-transition: color 0.3s;
			transition: color 0.3s;
			@include common_btn;
			width: 56px;
			height: 56px;
			@include row_center;
			svg {
				width: 16px;
				height: 16px;
				fill: rgba(0, 0, 0, 0.35);
			}
			&:hover {
				svg {
					fill: rgba(0, 0, 0, 0.7);
				}
			}
		}
		.header {
			color: rgba(0, 0, 0, 0.65);
			background: #fff;
			border-bottom: 1px solid #e8e8e8;
			border-radius: 4px 4px 0 0;
			padding: 16px 0;
			border-bottom: 1px solid #f78c1c;
			.title {
				margin: 0;
				color: rgba(0, 0, 0, 0.85);
				font-weight: 500;
				font-size: 1.25rem;
				line-height: 1.25rem;
				word-wrap: break-word;
			}
		}
		.body {
			font-size: 14px;
			line-height: 1.5;
			word-wrap: break-word;
			padding: 20px 0;
		}
	}
	.sendCode {
		display: flex;
		justify-content: space-between;
		.simpleInput {
			margin: 0;
			width: 100%;
		}
		.getCode {
			align-self: flex-start;
			width: 130px;
			height: rem(54px);
			margin-top: 20px;
			background: #f5f5f5;
			line-height: rem(54px);
			text-align: center;
			margin-left: 20px;
			color: #ba6a16;
			font-size: 14px;
			@include common_btn;
		}
	}
	.switchVerify {
		@include row_end;
		height: 24px;
		line-height: 24px;
		font-size: 12px;
		font-weight: 500;
		color: $Active_Color;
		> div {
			@include common_btn;
		}
	}
	.confirmBtn {
		margin-top: 15px;
	}
}
.checkInModal {
	width: 528px;
	height: 320px;
	background-color: #B69575;
	// position: relative;
	overflow: hidden;
	.ellipses {
		position: absolute;
		background-color: $white_color;
		width: 528px;
		height: 320px;
		border-bottom-left-radius: 40%;
		border-bottom-right-radius: 40%;
		bottom: 40px;
		z-index: 10003;
	}
	.close {
		position: absolute;
		right: 24px;
		top: 24px;
		pointer-events: auto;
		@include common_btn;
		z-index: 10004;
		svg {
			width: 18px;
			height: 18px;
		}
		&:hover {
			svg {
				fill: rgba(0, 0, 0, 0.5);
			}
		}
	}
	.checkInContent {
		z-index: 10004;
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 24px;
		@include column_start;
		align-items: center;
		.title {
			font-size: 24px;
			color: #323232;
			font-weight: 500;
			line-height: 24px;
		}
		.hint {
			color: #969696;
			line-height: 20px;
			margin-top: 16px;
		}
		.list {
			margin-top: 40px;
			width: 100%;
			@include row_sb;
			.item {
				width: 66px;
				@include column_sb;
				align-items: center;
				.point {
					position: relative;
					@include row_center;
					width: 50px;
					height: 50px;
					background: #afafaf;
					border-radius: 50%;
					color: $white_color;
					font-weight: 500;
					margin-bottom: 16px;
					&.checked {
						background-color: $Active_Color;
						box-shadow: 0px 0 5px 2px $Active_Color;
					}
					i {
						position: absolute;
						bottom: 8px;
						width: 5px;
						height: 10px;
						border-right: 1px solid $white_color;
						border-bottom: 1px solid $white_color;
						transform: rotate(40deg);
					}
				}
				span {
					color: #afafaf;
					font-size: 16px;
					line-height: 16px;
					&.active {
						color: #323232;
						font-size: 14px;
					}
				}
			}
		}
	}
}
