@import "../../style/variable";

#Activity{
  @include column;
  background: #151515;
  .banner_box{
    position: relative;
    .title{
      position: absolute;
      top: 120px;
      left: 300px;
    }
    .bg{
      width: 100%;
      height: 100%;
    }
  }
  main{
    flex: 1;
    width: $Max_Width;
    min-height: 700px;
    background: #333;
    margin: 40px auto 80px;
    >div{
      margin: 50px 0 80px;
      >img{
        height: 100%;
      }
      div{
        display: flex;
        justify-content: flex-start;
        margin-top: 50px;
        a{
          width: 300px;
          height: 253px;
          margin: 0 60px;
          img{
            width: 100%;
          }
        }
      }
    }
  }
}