@import "@/style/common.scss";

.modalWrapper {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 10000;

	.mask {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10001;
		background: rgba(0, 0, 0, 0.4);
	}
	.common {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 100002;
		// transition: transform 0.2s ease-in-out;
	}
}

.formModal {
	width: 528px;
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5;
	list-style: none;
	position: relative;
	pointer-events: none;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	z-index: 10002;
	border: 0;
	border-radius: 4px;
	animation: flyIn 0.2s ease-in-out;
	animation-fill-mode: forwards;

	@keyframes flyIn {
		0% {
			opacity: 0;
			scale: 0;
		}
		100% {
			opacity: 1;
			scale: 1;
		}
	}
	.content {
		position: relative;
		background-color: #fff;
		background-clip: padding-box;
		border: 0;
		border-radius: 4px;
		-webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
		pointer-events: auto;
		padding: 0 24px;
		padding-bottom: 20px;
		.close {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 10;
			padding: 0;
			color: rgba(0, 0, 0, 0.45);
			font-weight: 700;
			line-height: 1;
			text-decoration: none;
			background: transparent;
			border: 0;
			outline: 0;
			cursor: pointer;
			-webkit-transition: color 0.3s;
			transition: color 0.3s;
			@include common_btn;
			width: 56px;
			height: 56px;
			@include row_center;
			svg {
				width: 16px;
				height: 16px;
				fill: rgba(0, 0, 0, 0.35);
			}
			&:hover {
				svg {
					fill: rgba(0, 0, 0, 0.7);
				}
			}
		}
		.header {
			color: rgba(0, 0, 0, 0.65);
			background: #fff;
			border-bottom: 1px solid #e8e8e8;
			border-radius: 4px 4px 0 0;
			padding: 16px 0;
			border-bottom: 1px solid #f78c1c;
			.title {
				margin: 0;
				color: rgba(0, 0, 0, 0.85);
				font-weight: 500;
				font-size: 1.25rem;
				line-height: 1.25rem;
				word-wrap: break-word;
			}
		}
		.body {
			font-size: 14px;
			line-height: 1.5;
			word-wrap: break-word;
			padding: 20px 0;
		}
	}
}
