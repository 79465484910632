@import "../../style/variable";

.warningWrapper {
	@include column;
	background: #151515;
	main {
		height: 100%;
		background: #f1f1f1;
		padding-bottom: 0.25rem;
		overflow: auto;
		.banner img {
			width: 100%;
		}
		.body {
			width: 70rem;
			background-color: #fff;
			margin: 0 auto;
			padding-bottom: 0.25rem;
			margin-bottom: 0.75rem;
			.security01 {
				background: url("../../assets/images/bot/security01.jpg") no-repeat center;
				background-size: contain;
				height: 200px;
			}
			.security02 {
				background: url("../../assets/images/bot/security02.jpg") no-repeat center;
				background-size: contain;
				height: 200px;
			}
			.security03 {
				background: url("../../assets/images/bot/security03.jpg") no-repeat center;
				background-size: contain;
				height: 200px;
			}
			.security04 {
				background: url("../../assets/images/bot/security04.jpg") no-repeat center;
				background-size: contain;
				height: 200px;
			}
			.security05 {
				background: url("../../assets/images/bot/security05.jpg") no-repeat center;
				background-size: contain;
				height: 200px;
			}
		}
	}
}
