@import "@/style/common.scss";

.componentsChart {
  @include dis-clm();
  position: relative;
  height: 100%;
  .tradingViewBox{
    flex: 1;
    position: relative;
    .tradingView{
      width: 100%;
      height: 100%;
    }
    .modal{
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0,0,0,.2);
      width: 100%;
      height: 100%;
      text-align: center;
      div{
        margin-top: 40%;
        font-size: rem(14);
        color: $black_color;
      }
    }
  }
  
  .indicator_bar {
    height: rem(30);
    display: flex;
    padding: 0 rem(30);
    justify-content: space-between;
    border-top: rem(1) solid $line_bg;
    border-bottom: rem(1) solid $line_bg;
    color: $line_gray_bg;
    font-size: rem(14);
    // @include fontDM;
    font-weight: 500;
    .active {
      color: $main_color;
    }
    .left {
      display: flex;
      align-items: center;
      width: 40%;
      border-right: rem(1) solid $line_gray_bg;
      > div:first-child {
        margin-right: rem(20);
      }
    }
    .right {
      display: flex;
      width: 60%;
      margin-left: rem(20);
      justify-content: space-around;
      align-items: center;
      color: $line_gray_bg;
    }
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  li.active {
    color: $main_color;
    position: relative;
    font-weight: bold;
  }

  .menuBox {
    position: relative;
    height: rem(34px);
    .menu {
      width: 100%;
      line-height: rem(34px);
      background-color: $white_color;
      border-bottom: rem(1) solid $white_color;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 2;
      color: $black_color;
      font-size: rem(13);
      // @include fontDM;
      li {
        flex: 1;
        // @include fontDM;
        font-size: rem(12px);
        font-weight: 500;
      }

      .active {
        color: $main_color;
      }

      .active:after {
        content: "";
        width: rem(15px);
        height: rem(3px);
        background: $main_color;
        position: absolute;
        bottom: 0;
        left: 34%;
        margin: auto;
      }
      .rule {
        font-size: rem(13px);
      }

      .switch,
      .moreButtonBox,
      .switch-TV {
        display: flex;
        justify-content: center;
        position: relative;

        .triangleBox {
          display: flex;
          align-items: flex-end;

          .triangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 5px 5px;
            border-color: transparent transparent $white_color transparent;
            margin-bottom: rem(5px);
          }
        }

        &:before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: rem(20px);
          right: rem(3px);
          transform: rotate(135deg);
          border: 5px solid transparent;
          border-bottom-color: #848e9c;
          z-index: 1;
        }

        &.show {
          font-weight: bold;

          &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: rem(20px);
            transform: rotate(135deg);
            border: 5px solid transparent;
            border-bottom-color: $main_color;
            z-index: 1;
          }
        }
      }

      .zoomInAndOut,
      .switch-TV {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(34px);

        .C-Svg {
          zoom: 0.5;
        }
      }
    }

    .moreList {
      margin: 0 auto;
      position: absolute;
      bottom: rem(-44px);
      height: rem(44px);
      line-height: rem(44px);
      width: 100%;
      background: $white_color;
      z-index: 2;
      display: flex;
      .moreCell {
        width: rem(47px);
        font-size: rem(12px);
        font-weight: 500;
        color: #b6bcc7;
        // @include fontDM;
        &.hide {
          float: right;
        }
      }

      .active {
        color: $main_color;
        &:after {
          content: "";
          width: rem(15px);
          height: rem(3px);
          background: $main_color;
          position: absolute;
          bottom: rem(5px);
          left: 35%;
          margin: auto;
        }
      }
    }
    .m_1 {
      z-index: 3;
    }
    .moreListHidden {
      visibility: hidden;
    }
  }

  .loading-area{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.1);
    z-index: 10;
  }

}

.light {
  .menu {
    background: $white_color;

    .active {
      position: relative;
    }

    .active:after {
      content: "";
      width: rem(20px);
      height: rem(2);
      background: $main_color;
      position: absolute;
      bottom: 0;
      left: 30%;
      margin: auto;
    }

    .rule {
      font-size: rem(14px);
      font-weight: 500;
      color: $main_font_color;
    }
  }
}
