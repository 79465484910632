$RAISE: #ff5f57;
$FALL: #21bf73;
$White: #fff;
$Font_Color: #b2b2b2;
$Light_Font_Color: #7c8185;
$Gary_Color: #858585;
$Deep_Gary_Color: #333;
$Black_Font_Color: #151515;
$Active_Color: rgba(247, 140, 28, 1);
$Light_Active: #f7ce66;
$Black_Bg: #0d0e12;
$Deep_Section_Bg: #202020;
$Line_Color: rgba(255, 255, 255, 0.05);

$Max_Width: 1200px;
$baseWidth: 1920;
$baseHeight: 946;
$baseSize: $baseWidth/100;

#root {
	font-family: "cereal", "PingFang SC", "Noto Sans SC", "Microsoft YaHei", NSimSun, Ping, arial, Menlo, Monaco, Consolas,
		"Courier New", monospace, sans-serif, sans-serif;
	height: 100%;
	&.zh-TW {
		font-family: "cereal", "PingFang SC", "Noto Sans TC", "Microsoft YaHei", sans-serif;
	}
	&.vi-VN {
		font-family: "Source Sans Pro", "Microsoft YaHei", sans-serif;
	}
	&.ru-RU {
		font-family: "cereal", "Circular", "Microsoft YaHei", sans-serif;
	}
	> div {
		//height: 100%;
	}
}

@function rem($val) {
	@if unitless($val) == true {
		@return #{$val/$baseSize}rem;
	} @else {
		@return #{($val / ($val * 0 + 1)) / $baseSize}rem;
	}
}

@mixin column() {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

@mixin column_center() {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

@mixin boxShadow() {
	box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.08);
}

@mixin flex-row() {
	display: flex;
	align-items: center;
}
@mixin row_sb {
	@include flex-row;
	justify-content: space-between;
}
@mixin row_center {
	@include flex-row;
	justify-content: center;
}

@mixin un-select() {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
	cursor: default;
}

@mixin common_btn {
	@include un-select();
	cursor: pointer;
}

@mixin triangle($direction, $size, $tend, $color) {
	@content;
	width: 0;
	height: 0;
	@if $direction == top {
		border-bottom: $tend solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
	} @else if $direction == right {
		border-left: $tend solid $color;
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
	} @else if $direction == bottom {
		border-top: $tend solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
	} @else if $direction == left {
		border-right: $tend solid $color;
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
	}
}

@mixin screen($res-max) {
	@media only screen and (min-width: #{$res-max}px) {
		html,
		body {
			//font-size: #{($baseSize * ($res-max/$baseWidth))}px !important;
		}
	}
}

@mixin active_btn($background, $border, $color) {
	background: $background;
	border-top: 2px solid $border;
	color: $color;
}

@include screen(1366);
@include screen(1400);
@include screen(1440);
@include screen(1600);
@include screen(1680);
@include screen(1900);
@include screen(1920);
@include screen(1960);

.green {
	color: $FALL !important;
}

.red {
	color: $RAISE !important;
}

.rest {
	color: $Gary_Color !important;
}

.red_bg {
	background: $RAISE !important;
}
.yellow_bg {
	background: $Active_Color !important;
}
.green_bg {
	background: $FALL !important;
}

.confirm_btn {
	background: $Active_Color;
	color: $White;
	text-align: center;
	height: 48px;
	line-height: 48px;
	border-radius: 2px;
	@include common_btn;
	&:hover {
		background: $Light_Active;
	}
	&.error {
		background: #f5f5f5;
		color: $Gary_Color;
	}
	&.verify {
		background: #f5f5f5;
		color: $Black_Font_Color;
	}
}

.alertModal {
	width: 528px !important;
	top: 150px !important;
	.ant-modal-content {
		padding: 0 24px;
		.ant-modal-header {
			padding: 16px 0 !important;
			border-bottom: 1px solid $Active_Color;
			.ant-modal-title {
				font-size: rem(24px);
			}
		}
		.ant-modal-body {
			padding: 20px 0;
		}
		.ant-modal-footer {
			border: none;
		}
	}
}

.ant-select-dropdown {
	.ant-select-dropdown-menu {
		li {
			height: 40px;
			line-height: 30px;
			display: flex;
			&.ant-select-dropdown-menu-item-active {
				background: $Active_Color;
			}
			img {
				width: 24px;
				height: 24px;
				align-self: center;
				margin-right: 5px;
			}
		}
	}
}

.pagination {
	padding: 10px 20px;
	display: flex;
	justify-content: flex-end;
	color: #f5f5f5;
	.ant-pagination-total-text {
		color: $Font_Color;
	}
	.ant-pagination-item {
		background: transparent;
		color: $Font_Color;
		border: #f5f5f5;
		a {
			color: $Font_Color;
		}
		&:hover a {
			color: $Active_Color;
			border-color: $Active_Color;
		}
	}

	.ant-pagination-item-active {
		border-color: $Active_Color;
		color: $Active_Color;
		a {
			color: $Active_Color;
		}
	}
	.ant-pagination-next,
	.ant-pagination-prev {
		&:hover a {
			border-color: $Active_Color;
			svg {
				fill: $Active_Color;
			}
		}
	}
	.ant-pagination-item-link {
		background: transparent;
		border-color: #f5f5f5;
		svg {
			fill: $Font_Color;
		}
	}
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
	width: 2px;
	height: 3px;
	//display: none;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px $Deep_Section_Bg;
	border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #bbb;
	-webkit-box-shadow: inset 0 0 6px $Deep_Section_Bg;
}

@mixin modal-base() {
	.ant-modal-content {
		background: none;
	}
	.ant-btn:hover,
	.ant-btn:focus {
		border-color: $Active_Color;
	}

	.ant-modal-close-x {
		height: 44px;
		line-height: 44px;
	}

	.ant-modal-header {
		text-align: center;
		border: none;
		height: 44px;
		padding: 0;
		.ant-modal-title {
			height: 44px;
			line-height: 44px;
			font-weight: normal;
		}
	}

	.ant-modal-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		min-height: 120px;
		text-align: center;
	}
	.ant-modal-footer {
		border: none;

		div {
			display: flex;
			justify-content: center;
		}
		.ant-btn {
			width: 200px;
			height: 40px;
			line-height: 40px;
			font-size: 18px;
			font-weight: 500;
			border: none;
			margin: 0 15px;
		}
	}
}

@mixin modal-light() {
	.ant-modal-header {
		background: $White;

		.ant-modal-title {
			color: #333333;
		}
	}
	.ant-modal-body {
		background: #ffffff;
		color: #333333;
	}
	.ant-modal-footer {
		background: #ffffff;
	}
	.ant-btn {
		background: #eaeaea !important;
		color: #6e6f72 !important;
	}
	.ant-btn-primary {
		background: $White !important;
		//border-color: #e2be30;
		color: #333333 !important;
	}
}

@mixin modal-night() {
	.ant-modal-header {
		background: $Black_Bg;

		.ant-modal-title {
			color: #ffffff;
		}
	}
	.ant-modal-body {
		background: $Deep_Gary_Color;
		color: #ffffff;
	}
	.ant-modal-footer {
		background: $Deep_Gary_Color;
	}
	.ant-btn {
		background: $Black_Bg !important;
		color: #ffffff !important;
	}
	.ant-btn-primary {
		background: $Deep_Gary_Color !important;
		color: #333333 !important;
	}
}
