@import "../../../style/variable";

.NameVerify{
  height: 100%;
  @include column;
  .verify_title{
    height: 93px;
    padding: 24px;
    @include boxShadow;
    margin-bottom: 10px;
    display: flex;
    background: $White;
    justify-content: space-between;
    h5{
      height: 45px;
      font-size: rem(32px);
      color: $Black_Font_Color;
      line-height: 45px;
    }
    div{
      @include column;
      justify-content: flex-end;
      p{
        color: $Gary_Color;
        font-size: 12px;
      }
    }
  }
  .main{
    flex: 1;
    @include column;
    @include boxShadow;
    background: $White;
    .main_title{
      height: 55px;
      line-height: 55px;
      border-bottom: 1px solid #f9f9fa;
      text-align: center;
      font-size: 16px;
    }
    .main_info{
      flex: 1;
      width: 480px;
      height: auto;
      margin: 0 auto;
      padding-top: 40px;
      .confirm_btn{
        margin-top: 45px;
      }
      .verify_info{
        @include column;
        margin-top: 25px;
        width: 100%;
        label{
          height: 17px;
          font-size: 12px;
          color: $Gary_Color;
        }
        p{
          height: rem(54px);
          //swidth: 100%;
          line-height: rem(54px);
          font-size: 14px;
          background: #f5f5f5;
          border-radius: 2px;
          padding: 0 24px;
        }
      }
    }
  }
}