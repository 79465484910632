//颜色变量
$main_color:#d5b978;
$main_light_color:#e3cb93;
$white_color:#fff;
$black_color:#000;
$main_font_color:#333;
$line_color:#f4f4f4;
$gray_color:#939393;
$raise: #e34c4c;
$fall: #00b38f;
$Active_Color: rgba(247, 140, 28, 1);
$Black_Bg: #0d0e12;
$Deep_Section_Bg: #202020;
$Gary_Color: #858585;
$Deep_Gary_Color: #333;
$Black_Font_Color: #151515;
$font_color: #b2b2b2;
$Line_Color: rgba(255, 255, 255, 0.05);
$Light_Active: #f7ce66;
//
$maxWidth: 1200px;
$line_bg:#eeeff2;
$line_gray_bg:#b6bcc7;

$baseWidth: 375;
$baseSize: $baseWidth/10;

@function rem($val) {
    @if unitless($val) == true {
      @return #{$val/$baseSize}rem;
    } @else {
      @return #{($val / ($val * 0 + 1))/$baseSize}rem;
    }
};

@mixin screen($res-max) {
    @media only screen and (max-width: #{$res-max}px), only screen and (max-device-width: #{$res-max}px) {
      html,
      body {
        // font-size: #{($baseSize * ($res-max/$baseWidth))}px !important;
      }
    }
}
//mixins 混合
@mixin dis-sb{
    display: flex;
    justify-content: space-between;
}
@mixin dis-clm{
    display: flex;
    flex-direction: column;
}
@mixin dis-fs{
    display: flex;
    justify-content: flex-start;
}
@mixin dis-fe{
    display: flex;
    justify-content: flex-end;
}
@mixin dis-ct{
    display: flex;
    justify-content: center;
}
@mixin button($width:100,$height:30,$bg:$main_color, $color:$main_font_color, $border-color:$main_color, $font-size:14){
    width: rem($width);
    height: rem($height);
    background: $bg;
    color: $color;
    border: 1px solid $border-color;
    text-align: center;
    line-height: rem($height);
    border-radius: rem(3);
    font-size:rem($font-size);
    margin: auto;
}
@mixin un-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
}
@mixin common_btn {
	@include un-select();
	cursor: pointer;
}
//mixins 混合
@mixin flex-column() {
	display: flex;
	flex-direction: column;
}
@mixin column_sb() {
	@include flex-column;
	justify-content: space-between;
}
@mixin column_center() {
	@include flex-column;
	justify-content: center;
}
@mixin column_start() {
	@include flex-column;
	justify-content: flex-start;
}
@mixin column_end() {
	@include flex-column;
	justify-content: flex-end;
}
@mixin flex-row() {
	display: flex;
	align-items: center;
}
@mixin row_sb {
	@include flex-row;
	justify-content: space-between;
}
@mixin row_center {
	@include flex-row;
	justify-content: center;
}
@mixin row_start {
	@include flex-row;
	justify-content: flex-start;
}
@mixin row_end {
	@include flex-row;
	justify-content: flex-end;
}
@mixin triangle($direction, $size, $tend, $color) {
	@content;
	width: 0;
	height: 0;
	@if $direction == top {
		border-bottom: $tend solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
	} @else if $direction == right {
		border-left: $tend solid $color;
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
	} @else if $direction == bottom {
		border-top: $tend solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
	} @else if $direction == left {
		border-right: $tend solid $color;
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
	}
}
@mixin active_btn($background, $border, $color) {
	background: $background;
	border-top: 2px solid $border;
	color: $color;
}

@mixin confirm_btn {
	background: $Active_Color;
	color: $white_color;
	text-align: center;
	height: 48px;
	line-height: 48px;
	border-radius: 2px;
	@include common_btn;
	&:hover {
		background: $Light_Active;
	}
	&.error {
		background: #f5f5f5;
		color: $Gary_Color;
	}
	&.verify {
		background: #f5f5f5;
		color: $Black_Font_Color;
	}
}

.confirm_btn {
	background: $Active_Color;
	color: $white_color;
	text-align: center;
	height: 48px;
	line-height: 48px;
	border-radius: 2px;
	@include common_btn;
	&:hover {
		background: $Light_Active;
	}
	&.error {
		background: #f5f5f5;
		color: $Gary_Color;
	}
	&.disable {
		background: #f5f5f5;
		color: $Black_Font_Color;
	}
}

//antd样式
.adm-input{
  border: 1px solid $main_color;
  border-radius: rem(3);
  width: 70% !important;
  align-self: center;
  margin-top: rem(50);
  .adm-input-element{
    height: rem(80);
    padding:0 rem(20);
    font-size: rem(36);
    background-color: #fffcf7;
      &::placeholder{
        font-size: rem(36);
      }
  }
}
//checkbox
.adm-checkbox .adm-checkbox-icon{
  border-radius: rem(3);
  width: rem(18);
  height: rem(18);
}
.adm-checkbox.adm-checkbox-checked .adm-checkbox-icon{
  border: $main_color !important;
  background: $main_color !important;
}

.adm-tabs{
  @include dis-fs();
  flex-direction: column;
  flex: 1;
}
.adm-tabs-header{
  height: rem(38);
}
//可滑动 tabs
.adm-tabs-tab-line{
  left: 0;
  background: $main_color;
}
.adm-tabs-content{
  padding: 0 !important;
  flex: 1;
  overflow-y: auto;
}
.adm-tabs-tab-wrapper-stretch{
  display: flex;
  justify-content: center;
  .adm-tabs-tab{
      margin: 0;
      font-weight: 400;
      font-size: rem(14);
      &.adm-tabs-tab-active{
          color: $main_color;
      }
  }
}
.adm-swiper{
  height: 100% !important;
  overflow-y: auto !important;
}
.adm-list{
  --border-top: none!important;
}
.adm-list-item{
  padding: 0!important;
}
.adm-list-item-content{
  padding: 0 rem(15)!important;
}
.adm-list-item-content-main{
  width: 100%;
}

//picker
.adm-space-item{
  font-size: rem(15);
  line-height: rem(40);
  color: $black_color !important;
}

.adm-space{
  height: rem(40px);
 display: flex !important ;
 justify-content: space-between;
 align-items: center;
}

.adm-space-item{
  line-height: 0;
  color: $gray_color;
}

.adm-picker-header-button{
  color: #d5b977!important;
}
//下拉框高亮颜色
.adm-dropdown-item-highlight{
  color: $main_color !important;
}

.adm-dropdown-item .adm-dropdown-item-title{
  width: 100%;
  @include dis-sb();
}

.adm-collapse-panel-header .adm-list-item-content-main{
  font-size: rem(14)!important;
}

.adm-mask-content{
  height: 100%;
}

.adm-auto-center-content{
  text-align: center;
}

//modal
.adm-modal-content{
  >div{
    text-align: center;
    color: $gray_color;
  }
}
.adm-space.adm-space-block{
  display: flex!important;
}

.ant-notification {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum","tnum";
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin: 0 24px 0 0;
}