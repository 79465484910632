@import '@/style/common.scss';

.history{
    @include dis-clm();
    height: 100%;
    main{
        flex: 1;
        font-size: rem(14);
        padding-bottom: rem(15);
        overflow-y: auto;
        .list{
            padding:rem(10) rem(15);
            border-bottom: 1px solid $line_color;
            .top, .mid, .btm{
                @include dis-sb();
            }
            .top{
                div{
                    display: flex;
                    align-self: center;
                    img{
                        width: rem(34);
                        height: rem(34);
                    }
                    span{
                        align-self: center;
                    }
                    &.money{
                        font-size: rem(22);
                        line-height: rem(34);
                        color: $fall;
                    }
                }
            }
            .btm{
                margin: rem(5) 0;
                height: rem(32);
                div{
                    line-height: rem(32);
                    color: $gray_color;
                    &.status{
                        color: $black_color;
                        font-weight: 300;
                    }
                    &.cancel{
                        background: $main_color;
                        color: $white_color;
                        padding: 0 rem(10);
                        border-radius: rem(2);
                    }
                }
            }
        }
        .empty{
            margin-top: rem(200);
            img{
                display: block;
                width: rem(150);
                height: rem(150);
                margin: auto;
            }
            p{
                text-align: center;
            }
        }
    }
}