@import "../../../style/variable";

.HomeFoot {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .foot_info {
    width: 100%;
    background: #333333;
    .info_main {
      width: $Max_Width;
      margin: auto;
      padding: 85px 0 65px;
      display: flex;
      justify-content: space-between;
      .main_left {
        max-width: 700px;
        .foot_nav {
          font-size: 18px;
          display: flex;
          color: $White;
          margin-bottom: 20px;
          > div {
            margin-right: 30px;
            a {
              color: $White;
              @include common_btn;
              &:hover {
                color: $Active_Color;
              }
            }
          }
        }
        .info {
          p {
            font-size: 14px;
            font-weight: 300;
            color: #b2b2b2;
            line-height: 26px;
          }
        }
      }
      .main_right {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        font-size: 14px;
        .follow {
          @include column;
          span {
            color: $White;
            align-self: flex-start;
          }
          div {
            display: flex;
            justify-content: flex-start;
            margin-top: 20px;
            b,
            a {
              width: 24px;
              height: 24px;
              display: inline-block;
              margin-right: 20px;
              @include common_btn;
            }
            .wb {
              background: url("../../../assets/images/home/follow/no_color/wb.svg")
                no-repeat center;
              &:hover {
                background: url("../../../assets/images/home/follow/color/wb.svg")
                  no-repeat center;
              }
            }
            .wc {
              background: url("../../../assets/images/home/follow/no_color/wc.svg")
                no-repeat center;
              &:hover {
                background: url("../../../assets/images/home/follow/color/wc.svg")
                  no-repeat center;
              }
            }
            .tt {
              background: url("../../../assets/images/home/follow/no_color/tt.svg")
                no-repeat center;
              &:hover {
                background: url("../../../assets/images/home/follow/color/tt.svg")
                  no-repeat center;
              }
            }
            .ks {
              background: url("../../../assets/images/home/follow/no_color/ks.svg")
                no-repeat center;
              &:hover {
                background: url("../../../assets/images/home/follow/color/ks.svg")
                  no-repeat center;
              }
            }
            .bjh {
              background: url("../../../assets/images/home/follow/no_color/bjh.svg")
                no-repeat center;
              &:hover {
                background: url("../../../assets/images/home/follow/color/bjh.svg")
                  no-repeat center;
              }
            }
            .qq {
              background: url("../../../assets/images/home/follow/no_color/qq.svg")
                no-repeat center;
              &:hover {
                background: url("../../../assets/images/home/follow/color/qq.svg")
                  no-repeat center;
              }
            }
          }
        }
      }
    }
  }
  .foot_tips {
    background: #424242;
    font-size: 12px;
    color: #b2b2b2;
    .tips_main {
      width: $Max_Width;
      margin: auto;
      p {
        font-weight: 300;
        line-height: 22px;
        padding: 30px 0;
      }
      > div {
        display: flex;
        height: 47px;
        line-height: 47px;
        border-top: 1px solid rgba(242, 242, 242, 0.1);
        justify-content: space-between;
      }
    }
  }
}

.cs_alert {
  position: fixed;
  z-index: 5;
  right: 50px;
  bottom: 100px;
  @include common_btn;
}

.wcQr {
  width: 110px;
  height: 110px;
  background: $White;
  img {
    width: 100px;
    height: 100px;
    margin: 5px;
  }
  &.dy {
    width: 130px;
    height: 130px;
    img {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
}
.qqQr {
  width: 80px;
  height: 80px;
  background: $White;
  img {
    margin: 5px;
    width: 70px;
    height: 70px;
  }
}
