.rechargeTipsImg {
  position: relative;
  z-index: 1;
}
.copyOkex {
  position: absolute;
  width: 6%;
  height: 1%;
  z-index: 100;
  // left: 615px;
  left: 43.5%;
  bottom: 10.5%;
  cursor: pointer;
  // background:red
}
.copyHuobi {
  position: absolute;
  width: 6%;
  height: 1%;
  z-index: 100;
  // left: 615px;
  left: 50%;
  bottom: 10.5%;
  cursor: pointer;
  // background:red
}
