@import "../../../style/variable";

.simple_select{
  @include column;
  margin-bottom: 25px;
  .select_title{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    height: 16px;
    label{
      align-self: center;
      color: $Gary_Color;
    }
    a{
      color: $Active_Color;
      align-self: center;
    }
  }
  .select_box{
    width: 100%;
    height: rem(54px);
    outline: none;
    background: #f5f5f5;
    line-height: rem(54px);
    font-size: 16px;
    padding: 0 14px;
    border: none;
    border-radius: 2px;
    margin-top: 5px;
    &.ant-select-focused{
      .ant-select-selection{
        border: none;
        box-shadow: none;
      }

    }
    .ant-select-selection{
      height: 100%;
      border: none;
      background: #f5f5f5;
      .ant-select-selection__rendered{
        height: 100%;
        .ant-select-selection-selected-value{
          line-height: rem(54px);
          font-size: 14px;
        }
      }
    }

  }
}