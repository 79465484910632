@import "../../../style/variable";
@import "@/style/common.scss";

.user_center {
  flex: 1;
  @include column;
  height: 100%;
  width: 100%;
  .center_title {
    @include column;
    h5 {
      font-size: rem(32px);
      margin-bottom: 10px;
      color: $Black_Font_Color;
      padding: 24px;
      background: $White;
      @include boxShadow;
    }
    .user_box {
      padding: 24px;
      background: $White;
      @include boxShadow;
      margin-bottom: 10px;
      .checkin {
        @include row_center;
        @include common_btn;
        position: absolute;
        right: 382px;
        top: 240px;
        border: 1px solid $Active_Color;
        border-radius: 4px;
        width: 88px;
        height: 32px;
        font-size: 14px;
        color: $Active_Color;
        span {
          margin-left: 8px;
        }
      }
    }
    .user_info {
      height: 50px;
      display: flex;
      justify-content: flex-start;
      .info {
        @include column;
        padding: 2px 20px;
        .name {
          display: flex;
          font-size: 18px;
          color: $Black_Font_Color;
          img {
            align-self: center;
            width: 16px;
            height: 16px;
            margin-left: 10px;
          }
        }
        .id {
          display: flex;
          font-size: 14px;
          div {
            color: #48515d;
          }
          .phone {
            padding-right: 20px;
          }
          label {
            color: $Gary_Color;
            display: inline-block;
            margin-right: 5px;
          }
        }
      }
    }
    .login_info {
      font-size: 12px;
      display: flex;
      margin-top: 10px;
      span {
        width: 50px;
        display: block;
      }
      > div {
        display: flex;
        color: $Gary_Color;
        padding: 0 20px;
        p {
          margin-right: 20px;
        }
        a {
          color: $Active_Color;
          @include common_btn;
        }
      }
    }
  }
  .security_level {
    @include boxShadow;
    background: $White;
    padding: 24px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    b {
      align-self: center;
      font-size: 16px;
    }
    .line {
      padding: 0 25px;
      display: flex;
      > div {
        align-self: center;
        width: 60px;
        height: 4px;
        border-radius: 1px;
        margin: 0 5px;
        background: #f5f5f5;
      }
    }
    .level {
      width: 32px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      color: $White;
    }
    p {
      align-self: center;
      font-size: 12px;
      color: $Gary_Color;
      padding-left: 50px;
    }
  }
  .user_nav {
    height: 96px;
    background: $White;
    padding: 24px;
    @include boxShadow;
    display: flex;
    justify-content: space-between;
    .nav_name {
      @include column;
      b {
        font-size: 16px;
        line-height: 22px;
      }
      p {
        font-size: 12px;
        color: $Gary_Color;
      }
    }
    .btn {
      @include common_btn;
      align-self: center;
      width: 88px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      text-align: center;
      border: 1px solid $Active_Color;
      border-radius: 4px;
      color: $Active_Color;
      &:hover {
        background: $Active_Color;
        color: $White;
      }
    }
  }
  .nickname {
    color: #858585;
    font-size: 12px;
  }
}

.checkInModal {
  width: 528px;
  height: 320px;
  background-color: #f5f5f5;
  // position: relative;
  overflow: hidden;
  .ellipses {
    position: absolute;
    background-color: $white_color;
    width: 528px;
    height: 320px;
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    bottom: 40px;
    z-index: 10003;
  }
  .close {
    position: absolute;
    right: 24px;
    top: 24px;
    pointer-events: auto;
    @include common_btn;
    z-index: 10004;
    svg {
      width: 18px;
      height: 18px;
    }
    &:hover {
      svg {
        fill: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .checkInContent {
    z-index: 10004;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 24px;
    @include column_start;
    align-items: center;
    .title {
      font-size: 24px;
      color: #323232;
      font-weight: 500;
      line-height: 24px;
    }
    .hint {
      color: #969696;
      line-height: 20px;
      margin-top: 16px;
    }
    .list {
      margin-top: 40px;
      width: 100%;
      @include row_sb;
      .item {
        width: 66px;
        @include column_sb;
        align-items: center;
        .point {
          position: relative;
          @include row_center;
          width: 50px;
          height: 50px;
          background: #afafaf;
          border-radius: 50%;
          color: $white_color;
          font-weight: 500;
          margin-bottom: 16px;
          &.checked {
            background-color: $Active_Color;
            box-shadow: 0px 0 5px 2px $Active_Color;
          }
          i {
            position: absolute;
            bottom: 8px;
            width: 5px;
            height: 10px;
            border-right: 1px solid $white_color;
            border-bottom: 1px solid $white_color;
            transform: rotate(40deg);
          }
        }
        span {
          color: #afafaf;
          font-size: 16px;
          line-height: 16px;
          &.active {
            color: #323232;
            font-size: 14px;
          }
        }
      }
    }
  }
}
