@import "../../style/variable";

.disclaimerWrapper {
	@include column;
	background: #151515;
	main {
		height: 100%;
		background: #2a2a2a;
		padding: 1rem 0;
		padding-bottom: 0.25rem;
		color: #fff;
		overflow: auto;
		.head {
			width: 70rem;
			font-size: 1rem;
			margin: 0 auto;
			padding: 1rem;
			background-color: #0c0c0c;
			text-align: center;
		}
		.wrap {
			width: 70rem;
			margin: 0.75rem auto;
			background-color: #0c0c0c;
			padding: 0.5rem 1rem 2.5rem 1rem;
			border-radius: 0.15rem;
			p {
				font-size: 0.8rem;
				line-height: 1.6rem;
			}
			.txt {
				margin-top: 5px;
				font-size: 16px;
				line-height: 36px;
			}
			.title {
				font-size: 0.9rem;
				margin-top: 0.75rem;
				line-height: 1.8rem;
			}
		}
	}
}
