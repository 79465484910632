@import '../../../style/variable';

.emulate-input{
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid $Gary_Color;
  border-radius: 2px;
  height: 30px;
  &.float{
    border-top: none;
    border-left: none;
    border-right: none;
    margin: 0;
    &.error{
      box-shadow: none;
    }
    >label{
      color: #848E9C;
      font-size: 16px;
      font-weight: 500;
      transition: all .5s;
      &.fixed{
        font-size: 16.5px;
        transform: translate(-5px,-30px);
      }
    }
    >input{
      font-size: 16px;
    }
    >.verify-btn{
      height: 36px;
      top: auto;
      right: 10px;
    }
  }
  &.error{
    border-color: #ff6960!important;
    box-shadow: 0 0 5px #ff6960;
  }
  &.focus{
    border-color: $Active_Color;
  }
  >label{
    position: absolute!important;
    left: 6px;
    align-self: center;
    font-size: 13px;
    //color: #5E6469;
    color: #A7A8AC;
    font-family: "Myriad Pro"!important;
    pointer-events: none;
    &.fixed{
      color: $Gary_Color;
    }
  }
  >input{
    flex: 1;
    text-indent: 6px;
    font-size: 13px;
    color: #232E34;
    font-family: "Myriad Pro"!important;
    background: transparent;
    outline: none;
    width: 100%;
    border: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  >.smart-ass{
    position: absolute;
    width: calc(100% + 2px);
    background-color: #fff;
    top: calc(100% - 2px);
    z-index: 1;
    border: 1px solid;
    border-top-width:0;
    left: -1px;
    li{
      height: 34px;
      line-height: 34px;
      font-size: 12px;
      text-indent: 6px;
      cursor: pointer;
      letter-spacing: 1px;
      &:hover{
        background-color: #fafafa;
      }
    }
  }
  >.verify-btn{
    &.disabled{
      background: #b9b9b9;
      color: #fff;
      cursor: default;
    }
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background: $Active_Color;
    width: 100px;
    height: 100%;
    cursor: pointer;
    font-size: 13px;
    color: #000;
  }
  >.eye{
    @include common_btn();
    display: flex;
    margin-right: 10px;
  }
  >.controller{
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    align-self: center;
    align-items: center;
    height: 100%;
    width: 60px;
    right: 0;
    overflow: hidden;
    .add{
      width: 18px;
      height: 18px;
      cursor: pointer;
      .btn{
        width: 18px;
        height: 18px;
      }
    }
    .minus{
      width: 18px;
      height: 18px;
      cursor: pointer;
      .btn{
        width: 18px;
        height: 18px;
      }
    }
    .btn{
      @include common_btn();
      fill: $Active_Color;
    }
  }
  >.emulate-input-notice{
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    color: #ff6960;
  }
  .unit{
    position: absolute;
    right: 6px;
  }
}
