@import "../../style/variable";

#Trade {
  @include column;
  background: $Black_Bg;
  width: 100%;
  .trade_blc {
    background: $Deep_Section_Bg;
    display: flex;
    justify-content: space-between;
    margin: 10px;
    height: 58px;
    padding: 0 10px;
    .notice_info {
      display: flex;
      img {
        width: 20px;
        height: 20px;
        align-self: center;
      }
      .ant-carousel {
        align-self: center;
      }
      .notice_box {
        width: 272px;
        align-self: center;
        color: $White;
        margin: 0 12px;
        p {
          line-height: 58px;
        }
      }
      a {
        align-self: center;
        color: $Active_Color;
        font-size: 14px;
        padding-left: 15px;
        border-left: 1px solid $Active_Color;
      }
    }
    .blc_info {
      display: flex;
      padding: 10px 0;
      > div {
        @include column;
        margin-right: 30px;
        label {
          color: #fbcca0;
          font-size: 12px;
        }
        b {
          font-size: 18px;
          color: $White;
          &.real {
            color: $Active_Color;
          }
        }
        &.switch_btn {
          align-self: center;
          margin-left: 20px;
          display: flex;
          flex-direction: row;
          border-radius: 3px;
          overflow: hidden;
          div {
            height: 28px;
            line-height: 28px;
            width: 65px;
            text-align: center;
            font-size: 12px;
            color: $Gary_Color;
            border: 1px solid $Gary_Color;
            @include common_btn;
            &:last-child {
              border-left: none;
            }
            &.active {
              background: $Active_Color;
              color: $Black_Font_Color;
              border-color: $Active_Color;
            }
          }
        }
      }
    }
  }
  .trade_main {
    flex: 1;
    padding: 0 10px 10px;
    display: flex;
    justify-content: space-between;
    .trade_info {
      flex: 1;
      @include column;
      .trade_top {
        display: flex;
        height: 600px;
        .quotes {
          width: 300px;
          height: 100%;
          margin-right: 10px;
          @include column;
          .quotes_info {
            background: $Deep_Section_Bg;
            height: 100%;
            .quotes_nav {
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              > div {
                padding: 0 6px;
                span {
                  @include common_btn;
                  color: $White;
                  &.active {
                    color: $Active_Color;
                  }
                }
              }
              .ant-tabs-nav {
                .ant-tabs-tab {
                  margin: 0 !important;
                  color: $Gary_Color;
                  &:hover {
                    color: $Active_Color;
                  }
                }
                .ant-tabs-tab-active {
                  color: $Active_Color;
                }
              }
              .ant-tabs-bar {
                margin: 0;
                border: none;
              }
              .ant-tabs-ink-bar {
                background-color: transparent;
              }
            }
            .quote_box {
              position: relative;
              z-index: 10;
              .quotes_title {
                padding: 0 10px;
                display: flex;
                font-size: 12px;
                height: 30px;
                line-height: 30px;
                color: $Gary_Color;
                justify-content: space-between;
                div {
                  flex: 0.25;
                  display: flex;
                  justify-content: flex-end;
                  &:first-child {
                    flex: 0.4;
                    justify-content: flex-start;
                  }
                  &:last-child {
                    flex: 0.1;
                  }
                }
              }
              .quotes_list {
                height: 530px;
                overflow-y: auto;
                .list {
                  display: flex;
                  padding: 0 10px;
                  color: $White;
                  height: 44px;
                  line-height: 44px;
                  @include common_btn;
                  &:hover {
                    background: #252525;
                  }
                  .left_info {
                    display: flex;
                    flex: 0.9;
                    > div {
                      flex: 0.3;
                      display: flex;
                      justify-content: flex-end;
                      font-size: 12px;
                      &.name {
                        flex: 0.4;
                        justify-content: flex-start;
                        font-size: 14px;
                        img {
                          align-self: center;
                          width: 18px;
                          height: 18px;
                          margin-right: 5px;
                        }
                      }
                    }
                  }
                  .favor {
                    flex: 0.1;
                    display: flex;
                    justify-content: flex-end;
                    img {
                      align-self: center;
                      width: 16px;
                      height: 16px;
                    }
                  }
                }
              }
            }
          }
        }
        .chart {
          width: 100%;
          height: 100%;
          background: $Deep_Section_Bg;
          @include column;
          .chart_info {
            //height: 50px;
            padding: 12px 16px 12px;
            @include column;
            .info {
              display: flex;
              color: $White;
              user-select: none;
              > div {
                align-self: center;
                padding-right: 10px;
                display: flex;
                justify-content: flex-start;
                img {
                  width: 24px;
                  height: 24px;
                  margin-right: 5px;
                  margin-top: 6px;
                }
                &:last-child {
                  padding: 0;
                }
                span {
                  display: inline-block;
                  margin-right: 10px;
                  align-self: center;
                }
                b {
                  font-size: rem(30px);
                }
                label {
                  font-size: 12px;
                  text-align: center;
                  color: $Gary_Color;
                  margin-bottom: 5px;
                }
                div {
                  font-size: 14px;
                  text-align: center;
                }
                &:first-child {
                  font-size: 16px;
                }
                &.grid {
                  @include column;
                }
              }
              .info_name {
                display: flex;
                justify-content: space-between;
              }
            }
          }

          #tradingView {
            flex: 1;
          }
        }
      }
    }
  }
}

.empty {
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  div {
    margin: auto;
    position: absolute;
    width: 100px;
    height: 78px;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    @include column;
    img {
      width: 60px;
      margin: auto;
    }
    b {
      color: $Gary_Color;
      font-size: 12px;
      text-align: center;
    }
  }
}
