@import '@/style/common.scss';

.header{
    @include dis-sb();
    font-size: rem(13);
    height: rem(44);
    padding:0 rem(6);
    position: relative;
    z-index: 99;
    >div{
        flex:1;
        &.back{
            display: flex;
            svg{
                align-self: center;
                width: rem(20);
            }
        }
        &.title{
            flex: 2;
            text-align: center;
            line-height: rem(44);
            font-size: rem(16);
        }
        &.right{
            text-align: right;
            line-height: rem(44);
            align-self: center;
            a{
                color: $main_color;
                @include dis-fe();
                svg{
                    width: rem(24);
                    height: rem(24);
                }
            }
        }
    }
    &.darkHeader{
        background: #000;
        color: $white_color;
        .back{
            svg{
                fill:$white_color;
            }
        }
    }
    &.opacity{
        background: none;
        color: $white_color;
        >div{ 
            &.back{
                svg{
                    fill:$white_color;
                }
            }
            &.right a{
                color: $white_color;
            }
        }
    }
}

