@import "../../../style/variable";

#InviteHistory {
  .invite_title {
    height: 93px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    background: $White;
    @include boxShadow;
    h5 {
      align-self: center;
      font-size: rem(32px);
      line-height: 38px;
    }
  }
  .invite_info {
    display: flex;
    height: 100%;
    background: $White;
    @include boxShadow;
    margin-top: 10px;
    padding: 24px;
    div {
      @include column;
      flex: 1;
      label {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: $Black_Font_Color;
        line-height: 20px;
      }
      b {
        height: 29px;
        font-size: 24px;
        color: $Active_Color;
        line-height: 29px;
      }
    }
  }

  .my_user{
    background: $White;
    @include boxShadow;
    padding:0 24px;
    margin-top: 10px;
    .ant-tabs-bar{
      margin-bottom: 5px;
    }
    .ant-tabs-nav-container{
      .ant-tabs-nav .ant-tabs-tab{
        padding: 12px 0;

        &:hover{
          color: $Active_Color;
        }
        &.ant-tabs-tab-active{
          color: $Active_Color;
        }
      }
      .ant-tabs-ink-bar{
        background: $Active_Color;
      }
    }
    .user_box,.user_trade{
      .title{
        display: flex;
        height: 49px;
        justify-content: flex-start;
        margin-bottom: 5px;
        .title_section{
          align-self: center;
          display: flex;
          margin-right: 15px;
          label{
            align-self: center;
            font-size: 12px;
            color: $Deep_Section_Bg;
            margin-right: 10px;
          }
          >input{
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            width: 150px;
            border: $Font_Color;
            background: #f5f5f5;
            outline: none;
            padding-left: 10px;
            border-radius: 2px;
            &::placeholder{
              font-size: 12px;
            }
          }
          .dateWrapper{
              display: flex;
              span{
                align-self: center;
                &.line{
                  width: 30px;
                  text-align: center;
                }
              }
            }
          .ant-calendar-picker{
            background: #f5f5f5;
          }
          .ant-calendar-picker-input {
            color: $Black_Font_Color;
            &::placeholder {
              color: $Font_Color;
            }
          }
          .ant-calendar-picker-icon {
            color: $Gary_Color;
          }
          .ant-calendar-picker-input.ant-input {
            background: transparent;
            border-color: #f5f5f5;
            font-size: 12px;
          }
        }
        .search, .excel{
          width: 100px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          font-size: 12px;
          background: $Active_Color;
          color: $White;
          align-self: center;
          border-radius: 3px;
          margin-left: 20px;
          @include common_btn;
        }

        .excel{
          background: $Font_Color;
          color: $Deep_Section_Bg;
        }
      }
      ol{
        height: 484px;
        li{
          display: flex;
          height: 44px;
          line-height: 44px;
          border-bottom: 1px solid #f5f5f5;
          padding:0 12px;
          font-size: 14px;
          div{
            flex: 1;
            text-align: left;
            &:last-child{
              text-align: right;
            }
          }
          &.user_title{
            background: #f5f5f5;
            color: $Gary_Color;
            font-size: 12px;
          }
        }
      }
    }
    .user_trade{
      ol li{
        div{
          flex: none;
          width: 20%;
        }
      }
    }
  }
}