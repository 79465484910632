@import "../../style/variable.scss";

.agreement{
    .page-agree {
        width: 100%;
        background: #2a2a2a;
        padding-bottom: 0.25rem;
        padding: 1rem 0;
        color: #fff;
        .head{
            width: 60rem;
            font-size: 0.9rem;
            margin: 0 auto;
            padding: 1rem;
            background-color: #0c0c0c;
            text-align: center;
            position: relative;
            .return{
                position: absolute;
                left: 20px;
                cursor: pointer;
            }
        }
        .wrap{
            width: 60rem;
            margin: 0.75rem auto;
            background-color: #0c0c0c;
            color: #fff;
            padding: 0.5rem 1rem 2.5rem 1rem;
            border-radius: 0.15rem;
            .title{
                font-size: 0.8rem;
                margin-top: 0.75rem;
                line-height: 1.6rem;
            }
            p{
                font-size: 0.7rem;
                line-height: 1.4rem;
            }
            .subtitle{
                font-size: 0.75rem;
                margin-top: 0.25rem;
                line-height: 1.5rem;
            }
        }
    }
}