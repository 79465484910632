@import '../../style/variable';

#Account{
  @include column;
  background: #fafafa;
  .box{
    width: $Max_Width;
    min-height: 851px;
    margin: auto;
    >.main{
      margin: 20px auto;
      display: flex;
      justify-content: space-between;
      .account_nav{
        @include column;
        @include boxShadow;
        justify-content: flex-start;
        width: 200px;
        height: auto;
        margin-right: 10px;
        background: $White;
        padding: rem(10px) 0;
        .title{
          color: $Active_Color;
          height: 48px;
          display: flex;
          justify-content: flex-start;
          padding-left: 20px;
          font-size: 14px;
          line-height: 48px;
          user-select: none;
          .C-Svg{
            margin-right: 10px;
          }
        }
        a{
          color: #121319;
          height: 48px;
          line-height: 48px;
          padding-left: 53px;
          margin-bottom: 5px;
          font-size: 14px;
          &.active{
            position: relative;
            background: #f5f5f5;
            &:after{
              position: absolute;
              top: 0;
              left: 0;
              width: 4px;
              height: 100%;
              background: $Active_Color;
              content: '';
            }
          }
          &:hover{
            background: #f5f5f5;
          }
        }
      }
      .myAsset{
        flex: 1;
        width: 990px;
      }
    }
  }
}