@import "../../../style/variable";

#Bankcard{
  @include column;
  height: 100%;
  .bankcard_title {
    height: 93px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    background: $White;
    @include boxShadow;
    h5 {
      align-self: center;
      font-size: rem(32px);
      line-height: 38px;
    }
    div{
      width: 120px;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      color: $White;
      border-radius: 3px;
      text-align: center;
      background: #f78c1c;
      @include common_btn;
    }
  }
  .bank_main{
    padding: 24px;
    flex: 1;
    background: $White;
    margin-top: 10px;
    @include boxShadow;
    .switch_btn{
      display: flex;
      justify-content: flex-start;
      height: 46px;
      line-height: 46px;
      @include common_btn;
      border-bottom: 1px solid #f5f5f5;
      div{
        border-bottom: 2px solid $Active_Color;
      }
    }
    .bankcard_list{
      padding: 20px 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      max-height: 550px;
      overflow: auto;
      position: relative;
      .bank_box{
        position: relative;
        width: 293px;
        height: 150px;
        padding: 20px;
        border-radius: 4px;
        color: $White;
        display: flex;
        justify-content: flex-start;
        margin: 0 20px 20px 0 ;
        .img{
          width: 35px;
          height: 35px;
          background: $White;
          border-radius: 50%;
          align-self: center;
          padding: 2.5px;
          img{
            display: block;
            width: 30px;
            height: 30px;
          }
        }
        .info{
          @include column;
          padding-left: 15px;
          .bank_name{
            align-self: left;
            b{
              display: block;
              height: 21px;
              font-size: 15px;
              font-weight: 500;
              line-height: 21px;
              margin-bottom: 5px;
            }
            p{
              font-size: 12px;
            }
          }
          .bank_card{
            height: 26px;
            font-size: 19px;
            text-align: center;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: 1px;
          }
          .btn_box{
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            .right{
              display: flex;
              justify-content: flex-end;
              .btn:last-child{
                margin-left: 15px;
              }
            }
            .btn{
              padding:3px 5px;
              border: 1px solid $White;
              border-radius: 1px;
              @include common_btn;
            }
          }
        }
        .default{
          position: absolute;
          top: 10px;
          right: 0;
          width: 40px;
          height: 20px;
          font-size: 12px;
          line-height: 20px;
          background: url("../../../assets/images/userCenter/bank/default.svg") no-repeat center;
          background-size: 100%;
        }
      }
      .empty{
        height: 460px;
        width: 100%;
      }
    }
  }
  .icbc{
    background: url("../../../assets/images/userCenter/bank/bankBg/ICBC.png") no-repeat center;
    background-size: 120%;
  }
  .cmb{
    background: url("../../../assets/images/userCenter/bank/bankBg/CMB.png") no-repeat center;
    background-size: 120%;
  }
  .ccb{
    background: url("../../../assets/images/userCenter/bank/bankBg/CCB.png") no-repeat center;
    background-size: 120%;
  }
  .abc{
    background: url("../../../assets/images/userCenter/bank/bankBg/ABC.png") no-repeat center;
    background-size: 120%;
  }
  .boc{
    background: url("../../../assets/images/userCenter/bank/bankBg/BOC.png") no-repeat center;
    background-size: 120%;
  }
  .comm{
    background: url("../../../assets/images/userCenter/bank/bankBg/COMM.png") no-repeat center;
    background-size: 120%;
  }
  .cmbc{
    background: url("../../../assets/images/userCenter/bank/bankBg/CMBC.png") no-repeat center;
    background-size: 120%;
  }
  .spcb{
    background: url("../../../assets/images/userCenter/bank/bankBg/SPDB.png") no-repeat center;
    background-size: 120%;
  }
  .citic{
    background: url("../../../assets/images/userCenter/bank/bankBg/CITIC.png") no-repeat center;
    background-size: 120%;
  }
  .gbd{
    background: url("../../../assets/images/userCenter/bank/bankBg/GDB.png") no-repeat center;
    background-size: 120%;
  }
  .szpab{
    background: url("../../../assets/images/userCenter/bank/bankBg/SZPAB.png") no-repeat center;
    background-size: 120%;
  }
  .cib{
    background: url("../../../assets/images/userCenter/bank/bankBg/CIB.png") no-repeat center;
    background-size: 120%;
  }
  .hxb{
    background: url("../../../assets/images/userCenter/bank/bankBg/HXB.png") no-repeat center;
    background-size: 120%;
  }
  .ceb{
    background: url("../../../assets/images/userCenter/bank/bankBg/CEB.png") no-repeat center;
    background-size: 120%;
  }
  .psbc{
    background: url("../../../assets/images/userCenter/bank/bankBg/PSBC.png") no-repeat center;
    background-size: 120%;
  }
  .bcs{
    background: url("../../../assets/images/userCenter/bank/bankBg/BCS.png") no-repeat center right;
    background-size: 120%;
  }
  .common{
    background: url("../../../assets/images/userCenter/bank/bankBg/card-bg.png") no-repeat center;
    background-size: 120%;
  }
}